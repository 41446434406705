﻿@import '../tokens/colors.scss';

.vueperslides__parallax-wrapper {
	padding-bottom: 0 !important;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
	content: none;
	box-shadow: none !important;
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
	content: none;
	box-shadow: none !important;
}

.vueperslides__track {
	position: unset !important;
}

.vueperslides__bullet .default {
	background-color: $color-background-tertiary;
}

.vueperslides__bullet--active .default {
	background-color: var(--color-background-primary);
	border-width: 0;
}

.rin-show-partial-next-prev-slides {
	padding: 0 1rem;

	.vueperslides__parallax-wrapper,
	.vueperslides__track {
		overflow: visible;
	}

	.vueperslides__bullet--active .default {
		background-color: var(--color-background-primary);
		color: var(--color-background-primary);
	}
}

.rin-base-carousel {
	.vueperslides__arrow {
		opacity: 1 !important;

		&.vueperslides__arrow--next {
			transform: rotate(180deg);
		}

		.rin-dropdown-toggle-icon {
			color: var(--color-text-secondary);
			border: 1px solid $color-background-secondary-dark-3;
			border-radius: 50px;
			background: $color-background-secondary-dark-3;
			padding: 0.5rem;

			&:after {
				display: inline-block;
				font-family: 'icomoon';
				content: '\e91a';
				margin: auto;
				font-size: 0.75rem;
				transform-origin: center center;
				transition-property: transform;
				transition-duration: 0.3s;
				transform: rotate(90deg);
			}
		}
	}

	.vueperslides__bullets {
		bottom: -1.5rem;

		.vueperslides__bullet .default {
			background-color: var(--color-background-primary);
			color: var(--color-text-secondary);
			border: none;
			box-shadow: none;
			transition: 0.3s;
			width: 5px;
			height: 5px;
		}

		.vueperslides__bullet--active .default {
			width: 8px;
			height: 8px;
		}

		.vueperslides__bullet {
			margin: 0 0.375rem;

			span {
				display: none;
			}
		}
	}

	.vueperslides__arrow--next {
		right: 0;
	}

	.vueperslides__arrow--prev {
		left: 0;
	}
}
