﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-alert-danger {
    background: $alert-background-danger;
    color: $color-text-danger;
}
.rin-alert-info {
    background: #2f3f7d1a;
    color: var(--color-text-secondary);
}
.rin-alert-success {
    background: $alert-background-success;
    color: $color-text-success-2;
}

.rin-alert-white {
    background: $color-background-light;
    color: $color-text-success-2;
}

.rin-alert {
    font-size: $font-size-body;
    line-height: $font-height-body-compressed;
	font-style: italic;
    font-weight: $font-weight-card-info;
    padding: 0.375rem 1rem;
    white-space:normal;

	&.with-background-disabled {
		padding: 0 0.5rem;
		background: none;
	}
}