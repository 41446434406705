@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-register-email-password-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin-bottom: 0;
		display: flex;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;

		span {
			white-space: nowrap;
		}
	}

	h6 {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
		margin: 1rem auto;

		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.rin-register-email-password-subtitle {
		font-size: $font-size-body;
		line-height: $font-height-body;
		font-weight: 500;
		margin-bottom: 2rem;
	}
}
