﻿@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';

.tournament-controls {
    .container-controls > .toggle {
        min-width: 140px !important;
        height: 30px !important;
    }

    .container-controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .event-manager {
        display: flex;
        align-items: center;

		@media screen and (max-width: 1024px) {
			display: block;

			#event-manager {
				display: inline-block;
				margin: 0.6125rem 0;
			}

			.popover-wrapper {
				display: inline-block;
				margin-bottom: 0.6125rem 0;
			}


		}

        .star-indicator {
            margin-left: 1rem;
        }

        .premium-wrapper {
            display:flex;
            align-items:center;

			&>div {
				color: $color_text_premium;
				font-weight: 600;
			}
        }
    }

    .event-controls {
        display: flex;
		align-items: center;
    }


    @media(min-width:1024px) {
        .container-controls > .toggle {
            float: right !important;
            margin: 0 5px;
        }
    }

    @media(max-width:768px) {
        .container-controls > .toggle {
            margin: 5px 0;
        }

        #change-state-dropdown {
            margin: 5px 0;
        }
    }

    .tooltip-wrapper {
        max-width: 200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h4 {
            font-size: $font-size-h4;
            margin-bottom: 0.625rem;
        }

        div {
            text-align: center;
            font-size: $font-size-note;
        }
    }

    .not-published {
        justify-content: center;
    }

    .popover-wrapper {
        margin: auto 2px;
    }
    /*  .rin-toggle {
        .vue-js-switch:not(.toggled) {
            .v-switch-core {
                background-color: #ffffff !important;

                .v-switch-button {
                    background-color: #2f3f7d !important;
                }
            }

            .v-switch-label {
                color: #2f3f7d !important;
            }
        }
    }*/
}

.rin-tournament-admin-shared-controls {

    .container-controls {
        flex-wrap: wrap;
        gap: 5px;
    }

    .event-controls {
        flex-wrap: wrap;
    }
}
