﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-base-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }
}

.rin-base-radiobutton {
    border: 1px solid black;
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    padding: 4px;
    font-size: 10px;
    cursor: pointer;
}

.rin-btn-size-s {
    .rin-base-radiobutton {
        padding: 1px 0 0 0;
        height: 10px;
        width: 10px;

        .icon-questionmark {
            font-size: 5px;
        }
    }
}
