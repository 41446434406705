﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';



.private-match-info-container {
    font-size: $font-size-h4;

    .icon {
        font-size: 34px;
    }

    .h1 {
        font-weight: $font-weight-h1;
        font-size: $font-size-h1;
    }

    a {
        text-decoration: underline;
    }
}
