@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

table.dataTable.dtr-column > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
	content: '\e91a' !important;
	right: 0;
	top: 0 !important;
	bottom: 0;
	left: 0 !important;
	width: 10px !important;
	height: 10px !important;
	margin: auto;
	font-family: 'icomoon' !important;
	font-size: 8px;
	line-height: 10px !important;
	transform-origin: center center;
	transition-property: transform;
	transition-duration: 0.3s;
	background: none;
	border: none !important;
	box-shadow: none !important;
	color: #2f3f7d !important;
	margin-top: 0 !important;
	margin-left: 0.25rem !important;
	background-color: unset !important;
	text-align: unset !important;
	position: unset !important;
}

table.dataTable.dtr-column > tbody > tr.parent td.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent th.dtr-control:before,
table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
	transform-origin: center center;
	transition-property: transform;
	transition-duration: 0.3s;
	transform: rotate(180deg);
	content: '\e91a' !important;
	background-color: unset !important;
}

table.dataTable.dtr-column > thead > tr.rin-is-expanded-all > th.dtr-control:before {
	transform-origin: center center;
	transition-property: transform;
	transition-duration: 0.3s;
	transform: rotate(180deg);
	content: '\e91a' !important;
	background-color: unset !important;
}


table.dataTable.dtr-column > thead > tr > th.dtr-control {
	margin-left: 0.3rem;
	margin-top: 0.4375rem;
	position: relative;

	&::before {
		content: '\e91a' !important;
		right: 0;
		top: 2px !important;
		bottom: 0;
		left: 6px !important;
		width: 10px !important;
		height: 10px !important;
		margin: auto;
		font-family: 'icomoon' !important;
		font-size: 8px;
		line-height: 10px !important;
		transform-origin: center center;
		transition-property: transform;
		transition-duration: 0.3s;
		background: none;
		border: none !important;
		box-shadow: none !important;
		color: #2f3f7d !important;
		background-color: unset !important;
		text-align: unset !important;
		position: absolute !important;
		cursor: pointer;
		margin-left: 0;
	}
}

.table tr td {
	border-bottom: 1px solid rgba($color-border-secondary, 0.1);
	border-top: none;
	background: unset;
	color: var(--color-text-secondary);
	padding: 0.3625rem 0.125rem !important;
	vertical-align: middle;
	font-size: 0.75rem !important;
	font-weight: 500;
	border-radius: unset !important;
}

.dataTable tbody tr {
	background: var(--color-background-secondary);
}

.dataTable .dt-hasChild {
	background: var(--color-background-secondary);
}

.dataTable tr.child td.child {
	background: var(--color-background-secondary);
}

.table tr th {
	border-bottom: 1px solid rgba($color-border-secondary, 0.1);
	border-top: none;
	text-align: center !important;
	color: var(--color-text-secondary);
	font-size: 0.75rem !important;
	font-weight: 700;
	border-radius: unset !important;
	padding: 0.3625rem 0.125rem !important;
}

.table tr.child td.child {
	text-align: left !important;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
	border: none !important;
}

.paginate_button .page-link {
	border: none;
	font-size: 0.75rem;
	font-weight: 500;
	color: var(--color-text-secondary);
}
.page-item.active .page-link {
	color: var(--color-text-secondary);
	background-color: unset;
	font-weight: 700;
}

div.dataTables_wrapper {
	background: var(--color-background-secondary);

	div.dataTables_info,
	.dataTables_length {
		display: inline-block;
		margin-left: 0.5rem;
		font-size: $font-size-body;
		line-height: $font-height-body;
		font-weight: 500;
	}
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	padding: 0.5rem 0;
}

.dataTables_length {
	.custom-select {
		background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
			$color-background-tertiary no-repeat right 0.75rem center/8px 10px;
		border-radius: 50px;

		color: $color-text-tertiary;
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: 700;
		padding-left: 1rem;

		&:focus {
			box-shadow: none;
			border-color: $color-border-primary-dark-1;
		}

		option {
			font-weight: 700;
		}
	}
}

//remove default .... loader, our spinner set on the processing prop in basedatatable
div.dataTables_processing>div:last-child {
	display: none;
}

.dataTables_paginate .pagination .page-link:focus {
	box-shadow: none;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	justify-content: center !important;
}

.dataTable .rin-dt-align-left {
	text-align: left !important;
}

.dataTable tr:not(.child) {
	.rin-dt-ellpsis-overflow-1 {
		& > * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			vertical-align: bottom;

			@media screen and (max-width: 1024px) {
				width: 360px;
			}

			@media screen and (max-width: 860px) {
				width: 280px;
			}

			@media screen and (max-width: 640px) {
				width: 240px;
			}

			@media screen and (max-width: 480px) {
				width: 180px;
			}

			@media screen and (max-width: 320px) {
				width: 140px;
			}
		}
	}

	.rin-dt-ellpsis-overflow-420 {
		& > * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			vertical-align: bottom;

			@media screen and (max-width: 1024px) {
				width: 420px;
			}

			@media screen and (max-width: 860px) {
				width: 340px;
			}

			@media screen and (max-width: 640px) {
				width: 300px;
			}

			@media screen and (max-width: 480px) {
				width: 240px;
			}

			@media screen and (max-width: 320px) {
				width: 200px;
			}
		}
	}

	.rin-dt-ellpsis-overflow-3 {
		& > * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			vertical-align: bottom;

			@media screen and (max-width: 1024px) {
				width: 200px;
			}

			@media screen and (max-width: 860px) {
				width: 160px;
			}

			@media screen and (max-width: 640px) {
				width: 120px;
			}

			@media screen and (max-width: 480px) {
				width: 80px;
			}

			@media screen and (max-width: 320px) {
				width: 60px;
			}
		}
	}

	.rin-dt-ellpsis-overflow-max500 {
		& > * {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			vertical-align: bottom;
			width: 500px;

			@media screen and (max-width: 1024px) {
				width: 350px;
			}

			@media screen and (max-width: 860px) {
				width: 260px;
			}

			@media screen and (max-width: 640px) {
				width: 200px;
			}

			@media screen and (max-width: 480px) {
				width: 160px;
			}

			@media screen and (max-width: 320px) {
				width: 60px;
			}
		}
	}
}
