@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-modal-create-container {
	.icon-plus-private-match {
		font-size: 22px;
	}
	//#region overrides
	.rin-card {
		padding: 1rem 0;
	}

	.rin-card-header,
	.rin-card-body {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin: 0 0.5rem;
	}
	//#endregion
	// @TODO: export display props to common body-modals.scss ?
	.rin-modal-header {
		font-size: $font-size-h2;
		line-height: $font-height-h2;
		font-weight: $font-weight-h2;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
	}

	.rin-modal-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		max-width: 33rem;
		margin: auto;
		font-size: $font-size-h5;
		line-height: $font-height-h5;
		font-weight: 700;
		text-decoration: underline;

		@media screen and (max-width: 1024px) {
			max-width: 22rem;
			margin-bottom: 1rem;
		}
	}

	.rin-modal-row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 0.75rem;
		row-gap: 1.75rem;
		width: -moz-fit-content;
		width: fit-content;
		margin: auto;

		@media screen and (max-width: 1024px) {
			grid-template-columns: repeat(2, 1fr);
		}
		// justify-items: center;
		// display: flex;
		// flex-flow: row wrap;
		// justify-content: center;
		// column-gap: 0.75rem;
		// row-gap: 1.25rem;
		// & > .rin-modal-create-entry {
		// 	flex: 1 1 30%;
		// 	@media screen and(max-width: 1024px) {
		// 		flex: 1 1 44%;
		// 	}
		// }
	}

	.rin-modal-create-entry {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		cursor: pointer;

		& > div {
			max-width: 12rem;
			min-height: 6rem;
		}

		.rin-card-title {
			margin: -1.5rem auto 0;
		}
	}

	.rin-modal-create-card-header {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
		margin-top: 1rem;
		text-align: center;
	}

	.rin-modal-create-card-body {
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;
		font-weight: 500;
		white-space: normal;
		margin-top: 0.5rem;
	}
	// @TODO: export this, lets have a common way to describe redirects outside the app?
	.rin-modal-create-outbound-link {
		font-size: $font-size-outbound-link;
		line-height: $font-height-outbound-link;
		font-weight: $font-weight-outbound-link;
		font-style: italic;
		text-decoration: underline;
		margin-top: 0.25rem;
	}

	.first-row {
		grid-template-columns:unset;
	}
}
