@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-nav-item-primary {
	font-size: $font-size-nav-item-primary;
	line-height: $font-height-nav-item-primary;
	font-weight: $font-weight-nav-item-primary;
	padding-top: 1.3125rem;
	padding-bottom: 0.7rem;
	margin: 0 1rem;
	text-align: center;
	color: var(--color-text-secondary);
	text-decoration: none;
	display: inline-block;
	position: relative;

	@media screen and (max-width: 1024px) {
		padding-top: 0.8125rem;
		padding-bottom: 0.625rem;
		margin: 0 0.5rem;
		font-size: $font-size-nav-item-primary-s;
		line-height: $font-height-nav-item-primary-s;
	}

	&.router-link-active::after ,
	&.router-link-exact-active::after {
		position: absolute;
		content: '';
		height: 4px;
		background: $color-border-secondary;
		border-radius: 1rem;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&:hover {
		text-decoration: none;
		color: var(--color-text-secondary);
	}
}
