.rin-search-history {
	padding: 0 1em;
	
	.rin-search-history-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: bold;
		margin-bottom: 18px;

		h4 {
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			color: #2f3f7d;
			margin-bottom: unset;
		}

		span {
			cursor: pointer;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #0276ff;
		}
	}

	.rin-search-history-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;

			span {
				font-weight: bold;
				cursor: pointer;
			}

			a {
				cursor: pointer;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				color: #2f3f7d;
			}
		}
	}
}
