@import '../../tokens/colors.scss';

.rin-topnav {
    height: 80px;
    display: flex;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        padding: 0 15px;
    }

    .rin-topnav-back-button {
        color: var(--color-text-primary);
        margin-right: 0.5rem;
    }

    ul {
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        flex: 1;
        gap: 2rem;
        padding: 20px 0;
        justify-content: space-between;

        li {
            color: white;

            .rin-search-input {
                padding: 7px 15px;
                min-width: 20rem;
                background: #FFFFFF;
                border: 1px solid rgba(47, 63, 125, 0.1);
                box-sizing: border-box;
                border-radius: 50px;
                font-size: 12px;
                line-height: 16px;
                color: #888888;
                outline: unset;
            }

            &.rin-topnav-login-or-create {
                display: flex;
                gap: 1em;
                align-items: center;
                flex: 2;

                .rin-nav-login-or-create-wrap {
                    display: flex;
                    gap: 1em;
                    align-items: center;

                    .rin-btn-type-tertiary {
                        border-color: white;
                        color: white;
                    }

                    .italic {
                        font-style: italic;
                    }
                }
            }

            &.rin-topnav-search-wrap {
                position: relative;

                .icon-search {
                    position: absolute;
                    right: 10px;
                    color: #888888;
                    top: 8px;
                    font-size: 15px;
                }
            }

            &.rin-top-nav-logo {
                img {
                    @media screen and (max-width: 1024px) {
                        height: 20px;
                    }
                }

                &.logo-circle {
                    img {
                        border-radius: 50%;
                        object-fit: cover;

                        @media screen and (max-width: 1024px) {
                            height: 50px;
                            width: 50px;
                            border: 2px solid white;
                        }

                        @media screen and (min-width:1025px) {
                            height: 60px;
                            width: 60px;
                        }
                    }
                }
            }

            .rin-nav-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                position: relative;

                &:hover {
                    text-decoration: none;
                }

                &.notification-wrapper {
                    position: relative;

                    .circle {
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 13px;
                        text-align: center;
                        font-weight: 700;
                        border: none;
                        color: #FFFFFF;
                        position: absolute;
                        background-color: $color-background-notifications;
                        float: right;
                        top: -5px;
                        left: calc(46% + 8px);
                    }
                }

                .rin-nav-badge {
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    background-color: $color-background-notifications;
                    border-radius: 50%;
                    height: 15px;
                    width: 15px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                    font-size: .75rem;
                    color: white;
                }
            }

            .icon {
                font-size: 25px;
            }

            .rin-navlink-description {
                font-size: 10px;
            }

            &.rin-topnav-group {
                display: flex;
                align-content: center;
                gap: 2rem;

                @media screen and (max-width: 1024px) {
                    gap: 1.5rem;
                    width: 100%;
                    justify-content: space-between;

                    .rin-nav-item {

                        &:first-child {
                            margin-left: auto;
                        }

                        &:last-child {
                            margin-right: 0;
                            margin-left: auto;
                        }

                        .icon {
                            font-size: 28px;
                        }
                    }
                }

                &.rin-topnav-group-m {
                    flex: 2;
                    padding-left: 3rem;
                }
            }
        }
    }
}

.rin-navmenu-backdrop {

    @media screen and (min-width: 1024px) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    background: transparent;
    display: flex;
    min-height: 30rem;
    z-index: 1005; //bigger than the footer.
    .rin-navmenu-wrap {
        display: flex;
        flex-flow: nowrap;
        padding-bottom: 10px;
        justify-content: flex-end;
        overflow: visible;

        @media screen and (max-width: 1024px) {
            margin-top: 4.4375rem;
        }

        .rin-navmenu {
            height: fit-content;
            background-color: white;
            padding: 20px;
            width: 380px;
            background-color: #F8F8FA;
            padding: 22px;
            display: flex;
            flex-flow: column nowrap;

            @media screen and (min-width: 1024px) {
                box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                height: -moz-fit-content;
                height: fit-content;
            }

            .rin-navmenu-pwa-option {
                margin-top: auto;
                background-color: $color-background-secondary-dark-3;

                .rin-pwa-install-menu-option {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-around;
                    align-items: center;
                    padding-top: 0.3rem;
                    padding-bottom: 0.3rem;

                    img {
                        width: 2.5rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                    }

                    div {
                        display: flex;
                        flex-flow: column nowrap;
                        font-size: $font-size-note;
                        line-height: $font-height-body;
                        font-weight: 400;
                        margin: 0;
                        color: $color-text-dark-0;
                    }

                    h4 {
                        font-size: $font-size-h4;
                        line-height: $font-height-h4;
                        font-weight: $font-weight-h4;
                        margin: 0;
                        color: $color-text-dark-0;
                    }

                    button {
                        height: 1.6rem;
                        margin-right: 0.5rem;
                    }
                }
            }

            .rin-navmenu-user-card {
                a {
                    display: flex;
                    align-items: center;

                    img {
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                    }

                    .rin-player-info {
                        margin-left: 15px;

                        p {
                            margin: 0;

                            &.rin-player-name {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 20px;
                                color: #2F3F7D;
                            }

                            &.rin-player-link {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                color: #2F3F7D;
                            }
                        }
                    }
                }
            }

            .rin-navmenu-close {
                display: flex;

                @media screen and (min-width: 1024px) {
                    display: none;
                }

                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                padding-bottom: 8px;
                border-bottom: 1px solid rgba(47, 63, 125, 0.1);

                .rin-navmenu-menu {
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2F3F7D;
                }

                .rin-navmenu-close-icon {
                    cursor: pointer;

                    .icon {
                        font-size: 20px;
                        display: block;
                    }
                }
            }
        }

        .rin-navmenu-actions-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            margin-top: 18px;
            justify-content: space-between;

            .rin-navmenu-action {
                display: flex;
                width: 48%;
                margin-bottom: 3%;
                background-color: white;
                background: #FFFFFF;
                border: 1px solid rgba(47, 63, 125, 0.1);
                box-sizing: border-box;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                padding: 10px;

                &.custom-increase-font-size a .icon {
                    font-size: 1.875rem;
                }

                &.not-logged-in-menu-icon a .icon {
                    font-size: 1.375rem;
                }

                &.admin {
                    color: #F77700;
                }

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    text-decoration: unset;
                    cursor: pointer;
                    width: 100%;

                    .icon {
                        font-size: 1.75rem;

                        &.icon-club {
                            font-size: 38px;
                        }
                    }

                    .rin-action-title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                    }

                    .rin-action-description {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        margin: 0;
                    }
                }
            }
        }

        .rin-nav-login-or-create-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            gap: 10px;
            margin: 2rem 0;
            white-space: nowrap;

            .rin-btn-type-tertiary {
                color: #2f3f7c;
                background: transparent;
                border-color: #2f3f7c;
            }

            .italic {
                font-style: italic;
            }
        }

        .rin-navmenu-list {
            background: #FFFFFF;
            border: 1px solid rgba(47, 63, 125, 0.1);
            box-sizing: border-box;
            border-radius: 15px;
            overflow: hidden;
            margin-top: 1.5rem;

            &.logged-out-list {
                margin-bottom: 1rem;
            }

            ul {
                padding: 10px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 10px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    &.rin-admin-panel {
                        background-color: #ffce00;
                        color: white;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(47, 63, 125, 0.1);
                    }

                    &:hover {
                        &:not(.rin-nav-language-select) {
                            color: #00b9ff;
                        }
                    }

                    &.open {
                        .rin-menu-right {
                            transform: rotate(180deg);
                        }
                    }

                    .rin-menu-right {
                        transform: rotate(-90deg);
                        font-size: 8px;
                    }

                    &.rin-nav-language-select {
                        flex-direction: column;
                        display: none;

                        &.open {
                            display: block;
                        }

                        div {
                            display: flex;
                            width: 100%;
                            padding: 5px;

                            &:hover {
                                color: #00b9ff;
                            }

                            .flag-icon {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rin-main-content-mobile-menu-opened {
    @media screen and (max-width: 1024px) {
        background-color: #f8f8fa;
    }
}

.rin-profile-image {
    span {
        padding: 15px;
        background-color: #DADCE7;
        color: #2f3f7c;
        font-weight: bold;
        border-radius: 50%;
        display: block;
        text-transform: uppercase;
    }
}

.rin-topnav {
    .rin-profile-image {
        span {
            height: 50px;
            width: 50px;
            text-align: center;
        }
    }
}

.mobile-header {
    .rin-profile-image {
        div.img-placeholder {
            padding: 45px !important;
            width: 11.5rem;
            height: 11.5rem;
            margin: auto;
            background-color: #DADCE7;
            color: #2f3f7c;
            font-weight: bold;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
            text-transform: uppercase;
        }
    }
}

.rin-profile-header-record-left {
    .rin-profile-image {
        width: 11.5rem;
        height: auto;

        @media screen and (max-width: 1024px) {
            width: 7.25rem;
            height: auto;
        }

        span {
            padding: 45px !important;
            margin: auto 36px;

            @media screen and (max-width: 1024px) {
                margin: auto;
            }
        }

        img {
            width: 11.5rem;
            height: 11.5rem;
            border-radius: 50%;
            object-fit: cover;

            @media screen and (max-width: 1024px) {
                width: 7.25rem;
                height: 7.25rem;
            }
        }

        div.img-placeholder {
            padding: 45px !important;
            width: 11.5rem;
            height: 11.5rem;
            margin: auto;
            background-color: #DADCE7;
            color: #2f3f7c;
            font-weight: bold;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
            text-transform: uppercase;
        }
    }
}

    .rin-navmenu-list .pro-color {
        color: $color-text-pro;
    }
