@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

@import '~vue2-datepicker/scss/index.scss';

.xmx-input {
	color: var(--color-text-secondary) !important;
	background: $color-background-tertiary;
	border-color: $color-background-tertiary;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	font-size: 0.8125rem;
	line-height: 1.0625rem;
	font-weight: bold !important;
	border-width: 2px;
	border-radius: 50px !important;
	border-style: solid;
	box-shadow: none !important;
	cursor: pointer;

	&::placeholder {
		color: var(--color-text-secondary);
	}
}

.xmx-datepicker-popup {
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
}

.xmx-icon-calendar {
	color: var(--color-text-secondary);
	right: 0.8125rem;
}

.xmx-table {
	background: var(--color-background-secondary);

	td {
		padding: 0 !important;
		border: none;
		border-radius: 0 !important;
		font-size: unset;
		background: var(--color-background-secondary);
	}
}
