@import '../../tokens/colors.scss';

.rin-profile-events-container {
	.rin-simple-tab-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		.rin-simple-tab {
			width: 50%;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			text-align: center;

			border: 1px solid rgba(47, 63, 125, 0.1);
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			border-bottom: none;

			font-weight: 700;
			font-size: 13px;
			line-height: 19px;
			color: #2f3f7d;
			background: #f5f5f9;

			cursor: pointer;
			height: 2.5rem;

			&.active {
				background: #ffffff;
			}
		}
	}

	.profile-events-placeholder-container {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		padding-bottom: 2rem;
	}

	.profile-events-placeholder-container h2 {
		text-align: center;

		font-weight: 800;
		font-size: 18px;
		line-height: 26px;
		color: #2f3f7d;
		margin-bottom: 0;
	}

	.profile-events-placeholder-container p {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #2f3f7d;

		text-align: center;
	}

	.profile-events-placeholder-container .rin-text-underline {
		text-decoration: underline;
	}

	.profile-events-placeholder-buttons-container {
		display: flex;
		gap: 0.5rem;
	}

	.star-indicator {
		vertical-align: text-top !important;
	}
}

.joined-events-teamleague-teams-modal {
	h3 {
		font-weight: 800;
		font-size: $font-size-h3;
		line-height: $font-height-h3;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:first-letter {
			text-transform: uppercase;
		}
	}

	.teams-modal-list-container {
		list-style-type: none;

		color: $color-text-info-1;
		cursor: pointer;
	}
}
