﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rating-widget-wrapper {
    .rin-dropdown-container {
        flex: 1;

        .rin-dropdown-toggle {
            width: 100%;
			border: none;

            span:first-of-type {
                text-overflow: ellipsis;
                overflow:hidden;
                white-space:nowrap;
            }
        }
    }
}
.matches-watermark-container {
    grid-column: 1/-1;

    img {
        height: 10rem;
        width: 10rem;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;

    .watermark-wrapper {
        text-align: center;
        width: 16rem;
        height: 25rem;
        margin-top: 3rem;
    }

    .watermark-buttons {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-top: 0.5rem;
    }

    .watermark-first-line {
        font-size: $font-size-h2;
        color: var(--color-text-secondary);
        font-weight: $font-weight-h2;
    }

    .watermark-second-line {
        font-size: $font-size-h4;
        color: var(--color-text-secondary);
        font-weight: $font-weight-not-so-bold;
    }


    .watermark-smallfont-line {
        font-size: $font-size-body;
        font-weight: $font-weight-breadcrumbs;
    }
}


@media screen and (min-width: 1024px) {
    .matches-alert {
        text-align: center;
    }
}
