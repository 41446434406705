@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-main-body-modal-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; //@TODO: when implementing the footer make sure the footer zindex is higher on mobile screen

    background: $color-background-secondary-dark-2;
    overflow-x: auto;

    &.rin-body-modal-ads-offset {
        padding-bottom: 60px;
    }


    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        bottom: 4.4375rem;
    }

    & > div {
        position: relative;
        width: 1024px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2.875rem;

        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }

    .rin-main-body-modal-close-btn {
        position: absolute;
        right: 0;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            right: 1rem;
        }
    }

    .rin-modal-header {
        font-size: $font-size-h2;
        line-height: $font-height-h2;
        font-weight: $font-weight-h2;
        display: flex;
        flex-flow: row nowrap;
        /*justify-content: start;*/

        @media screen and (min-width: 1024px) {
            justify-content: center;
        }

        @media screen and (max-width: 1024px) {
            justify-content: start;
            margin: 0 1rem;
        }
    }
}
