@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-type-label {
	font-weight: $font-weight-label;
	font-size: 11px;
	line-height: 11px;
	color: #fff;
	border-radius: 3px;
	padding: 2px 3px;
	margin: 0 3px; //to offset the cutting from the skewing
	transform: skew(-15deg, 0);
	display: inline-block;


	.tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}

	&:hover .tooltiptext {
		visibility: visible;
	}

	&.tournament {
		background: $tournament-label;
	}

	&.clubleague {
		background: $clubleague-label;
	}

	&.teamleague {
		background: $teamleague-label;
	}

	&.player {
		background: $player-label;
	}

	&.ranking {
		background: $ranking-label;
	}

	&.organisation {
		background: $organization-label;
	}

	&.organisation-club {
		background: $organization-label;
	}

	&.organization {
		background: $organization-label;
	}

	&.americano {
		background: $americano-label;
	}

	&.team {
		background: $team-label;
	}

	&.playerprofile {
		background: $color-background-warning;
	}

	&.notapproved {
		background: $color-background-danger;
	}

	&.approved {
		background: $color-background-success;
	}

	&.invite {
		background: $color-background-warning;
	}

	&.add {
		background: $color-background-success;
	}

	&.fake {
		background: $color-background-danger;
	}

	&.pro {
		background: $color-background-pro;
		font-weight: bold;
	}

	&.pro-invert {
		color: $color-text-pro;
		background: var(--color-background-secondary);
	}

	&.notpro {
		background: $color-background-notpro;
	}

	&.popular {
		background: $color-background-popular;
	}

	&.premium-invert {
		color: $color-text-premium;
		background: var(--color-background-secondary);
	}

	&.premium {
		color: var(--color-text-primary);
		background: $color-background-premium;
	}

	&.live,
	&.replay {
		background: $replay-label;
	}

	&.video {
		background: $video-planned-label;
	}

	&.current_plan,
	&.score {
		color: var(--color-text-primary);
		background: var(--color-background-primary);
	}

	&.license-private,
	&.state-finished {
		color: var(--color-text-secondary);
		background: $color-background-label-inverted;
		font-weight: $font-weight-card-label;
	}

	&.state-active,
	&.license-active,
	&.state-upcoming,
	&.state-signupclosed,
	&.state-matchesgenerated,
	&.state-generatingmatches {
		color: $color-text-success-3;
		background: $color-background-label-inverted;
		font-weight: $font-weight-card-label;
	}

	&.license-inactive,
	&.state-canceled {
		color: $color-text-danger-3;
		background: $color-background-label-inverted;
		font-weight: $font-weight-card-label;
	}

	&.privatematch {
		color: var(--color-text-secondary);
		background: $color-background-secondary-dark-3;
		font-weight: $font-weight-card-label;
	}
}
