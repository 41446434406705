@import '../../tokens/colors.scss';

.rin-navbar-scroll-arrow-container {
	position: relative;
	cursor: pointer;

	background: var(--color-background-secondary-dark-1);
	border-radius: 50%;
	width: 2rem;
	height: 2rem;

	display: flex;
	justify-content: center;
	align-items: center;
}
