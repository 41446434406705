﻿@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.profile-americano-wrapper {
    min-height: 400px;

    .americano-subtitle {
        font-weight: $font-weight-h3;
        font-size: $font-size-h3;
        line-height: $font-height-h3;
        text-align: center;
    }

    .rin-page-size-picker-wrapper {
        margin-bottom: 1rem;
    }

    .rin-page-picker-wrapper {
        position: absolute;
        width: 95%;
        bottom: 1rem;
    }


    .rin-page-size-picker-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .new-americano-wrapper {
        margin-top: 2rem;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;

        .vertical-align {
            vertical-align: middle;
        }
    }

    .americano-wrap {
        margin: 0 0 3rem;
        padding: 0;
        list-style: none;

        @media (min-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
            display: flex
        }

        & > li {
            margin: 0 0 11px 0;
            padding: 9px 18px 10px 21px;
            background: #FFFFFF;
            border: 1px solid rgba(47, 63, 125, 0.1);
            border-radius: 15px;
            display: flex;

            @media (min-width: 1024px) {
                margin: 0 11px 11px 0 !important;
                width: 48%;
            }

            .americano-menu {
                width: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                position: relative;
                justify-content: center;
            }

            .americano-info {
                flex: 1;
            }

            .americano-title {
                font-weight: 700;
                font-size: $font-size-h4;
                line-height: $font-height-h4;
                color: var(--color-text-secondary);
                margin: 0 0 15px 0;
                text-decoration: underline;
            }

            .americano-club {
                font-weight: 500;
                font-size: $font-size-h5;
                line-height: $font-height-list;
                font-style: normal;
                color: var(--color-text-secondary);
                margin: 0;

                .circle-green {
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: #36D076;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                }

                .circle-red {
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: #EF3340;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                }
            }

            .americano-date {
                margin: 0;
                font-style: normal;
                font-weight: $font-weight-breadcrumbs;
                font-size: $font-size-h5;
                line-height: $font-height-list;
                color: var(--color-text-secondary);
                display: flex;
                flex-direction: column;
            }
        }
    }

    .no-americanos-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4rem;

        .no-americanos-header {
            font-weight: $font-weight-h2;
            font-size: $font-size-h2;
            line-height: $font-height-list;
            text-align: center;
            color: var(--color-text-secondary);
            margin-bottom: 0;
        }

        .no-americanos-img {
            max-width: 80%;
            margin: 23px 0;
        }

        .no-americanos-footer {
            font-style: normal;
            font-weight: $font-weight-h2;
            font-size: $font-size-h6;
            line-height: 15px;
            text-align: center;
            margin: 0;
        }
    }
}