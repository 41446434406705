@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';

.rin-player-ranks-table-container {
	@media screen and (max-width: 1024px) {
		margin-left: -5rem;
	}

	display: grid;
	grid-template-columns: auto auto;
	gap: 0.25rem;

	.rin-ranks-header {
		min-width: 10rem;
		min-height: 2.25rem;

		font-size: $font-size-h4;
		line-height: $font-height-h4;
		font-weight: $font-weight-h4;
		color: var(--color-text-primary);
		background: var(--color-background-primary);
		border-radius: 5px;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.rin-ranks-data {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		height: 4rem;
		background: $color-background-tertiary;
		margin-top: 0.25rem;
		border-radius: 5px;
		border: 1px solid $color-border-primary-dark-1;
		color: var(--color-text-secondary);

		span:first-of-type {
			font-size: $font-size-h2;
			line-height: $font-height-h2;
			font-weight: $font-weight-h2;
		}
	}

	.rin-career-ranks {
		.rin-ranks-data {
			height: 8.25rem;
		}
	}
}
