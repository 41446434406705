@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-confirm-create-event-container {
	.rin-confirm-create-title {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		p {
			font-size: $font-size-h4;
			line-height: $font-height-h4;
			font-weight: $font-weight-h4;
			text-align: center;
		}

		.icon {
			font-size: 1.5rem;
		}
	}

	.rin-confirm-create-body {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: flex-end;
		padding: 1rem 0;
		background: var(--color-background-secondary-dark-1);
		border-color: $color-border-tertiary;
		border-radius: 10px;

		.rin-confirm-create-sport-selection {
			label {
				font-size: $font-size-body-compressed;
				line-height: $font-height-body-compressed;
				font-weight: $font-weight-body-compressed;
			}
		}

		a {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-body-compressed;
			display: block;
			text-align: center;
			margin-bottom: 0.5rem;
		}
	}

	.rin-confirm-create-footer {
		button {
			display: block;
			margin: 1rem auto;

			a:hover {
				color: var(--color-text-primary);
				text-decoration: none;
			}
		}
	}
}
