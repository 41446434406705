﻿@import '../../../tokens/fonts.scss';

.rin-start-finish {
    .rin-start-finish-controlls-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        .rin-dropdown-container {
            overflow: visible;

            .rin-dropdown-toggle {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    justify-content: center;
                }
            }

            ul {
                //fix for displaying dropdown outside of container
                //TODO: come up with general solution
                overflow: visible;

                li {
                    width: 18rem;
                    //custom background because of overflow visible
                    //TODO: come up with general solution
                    background-color: #f3f3f3;

                    &:hover {
                        color: #2f3f7c;
                    }
                }
            }
        }

        tr > td:first-of-type,
        tr > th:first-of-type {
            width: 3rem;
        }

        tr > td:nth-child(2),
        tr > th:nth-child(2) {
            text-align: left;
        }
    }

    .rin-standings-input {
        width: 2.5rem;
        text-align: center;
    }

    .rin-points-input {
        width: 100%;
    }

    .rin-tournament-result-footer-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: .5rem;
    }

    .rin-tournament-start-search-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        input {
            flex: 1;
        }
    }

    .jcr {
        display: flex;
        justify-content: flex-end;
    }

    table {
        tr {
            td {
                vertical-align: middle;
                white-space: nowrap;
            }
        }
    }

    .edit-tournament-point-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        .controls-container {
            @media screen and (max-width: 1024px) {
                margin-bottom: 1rem;
            }

            button {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }

    .download-standings-container {
        display: flex;

        .download-standings {
            margin: 0;
        }
    }

    .controls-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.2rem;
    }

    .forbidden {
        color: gray;
        touch-action: none;
        cursor: not-allowed;
    }

    .rin-standings-table {
        @media screen and (max-width: 1024px) {
            display: block;
            overflow-x: auto;
        }

        thead, tbody {
            tr {
                th, td {
                    &:nth-child(3) {
                        min-width: 10rem;
                    }
                }
            }
        }
    }
}
