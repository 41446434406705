@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

/*.settings-modal-wrapper {

    .rin-main-body-modal-close-btn {
        display: none !important;
    }
}*/
@media only screen and (min-width: 1024px) {
    .settings-wrapper {
        padding: 3rem 10rem;
    }
}

@media only screen and (max-width: 1023px) {
    .settings-wrapper {
        padding: 2rem 0;

        .settings-title {
            justify-content: start !important;
            padding-left: 1.5rem;
        }

        hr {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
}

.settings-wrapper {
  
    .settings-title {
        font-size: $font-size-h2;
        font-weight: $font-weight-h2;
        line-height: $font-height-h2;
        display: flex;
        justify-content: center;
    }

    .settings-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .download-data {
        text-decoration: none;
        justify-self: right;
    }

    .settings-body {
        @media only screen and (min-width: 1024px) {
            .rin-card {
                padding: 0 10rem;
            }
        }



        .settings-list {
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 15px;
            overflow: hidden;
            margin-top: 1.5rem;

            ul {
                padding: 10px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 10px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    /*grid-template-columns: repeat(3, 1fr);*/
                    /*   display: flex;
                justify-content: space-between;
                align-items: center;*/
                    cursor: pointer;

                    button {
                        justify-self: right;
                        margin-left: auto;
                    }

                    .setting-name {
                        font-size: $font-size-body;
                        line-height: $font-height-body;
                        font-weight: $font-weight-body;
                        justify-self: baseline;
                        white-space: normal;
                        flex: 0 0 33%;
                    }

                    .setting-value {
                        font-size: $font-size-body;
                        line-height: $font-height-body;
                        font-weight: $font-weight-not-so-bold;
                        justify-self: left;
                        flex: 0 0 33%;
                        white-space: normal;
                        margin-left:0.5rem;
                    }
                    a {
                        margin-left:auto;
                    }
                    .icon-v {
                        transform: rotate(270deg);
                        justify-self: right;
                        font-size: 0.6rem;
                        margin-left: auto;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(47, 63, 125, 0.1);
                    }
                }
            }
        }

        .rin-toggle {
            justify-self: right;
            margin-left: auto;

            .vue-js-switch:not(.toggled) {
                .v-switch-core {
                    background-color: #979FBE !important;

                    .v-switch-button {
                        background-color: #ffffff !important;
                        height: 1.225rem !important;
                        width: 1.375rem !important;
                    }
                }
            }

            .vue-js-switch.toggled {
                .v-switch-core {
                    background-color: $color-background-success !important;

                    .v-switch-button {
                        background-color: #ffffff !important;
                        height: 1.225rem !important;
                        width: 1.375rem !important;
                    }
                }
            }
        }
    }
}

.settings-modal {
    .date-dropdowns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;

        select {
            background: $color-background-tertiary;
            border-radius: 50px;
            color: var(--color-text-secondary);
            font-size: $font-size-h6;
            line-height: $font-height-h6;
            font-weight: 700;
            padding: 0.375rem 1rem;
            border: 1px solid $color-border-tertiary;
        }
    }

    .rin-alert-danger {
        background: unset !important;
        text-align: center;
    }

    .offset-wrapper {
        .rin-dropdown-container {
            .rin-dropdown-toggle {
                min-width: 14rem;
            }
        }
    }

    .country-wrapper {
        .rin-dropdown-toggle {
            min-width: 14rem;
        }
    }

    .settings-gender-label {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-h6;
    }

    .settings-name-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        margin: auto;
        gap: 1.5rem;

        h1 {
            font-size: $font-size-h1;
            line-height: $font-height-h1;
            font-weight: $font-weight-h1;
            margin-bottom: 0;
            text-align: center;
        }

        .input-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .rin-dropdown-container {
                margin: auto;
            }

            .setting-label {
                font-size: $font-size-body;
                line-height: $font-height-body;
                font-weight: $font-weight-not-so-bold;
                padding-left: 0.7rem;
            }

            &-row {
                display: flex;
                justify-content: center;
                flex-direction: row;
                gap: 0.85rem;

                .setting-label {
                    font-size: $font-size-body;
                    line-height: $font-height-body;
                    font-weight: $font-weight-not-so-bold;
                    margin: auto 0;
                }

                .rin-dropdown-container {
                    margin: auto 0;
                }
            }
        }
    }



    input.rin-input {
        border-radius: 3rem;
        background: #F8F8FA;
        border: 1px solid rgba(47, 63, 125, 0.1);
        padding: 0.6rem;
        color: var(--color-text-secondary) !important;
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-breadcrumbs !important;
    }

    .save-button {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .rin-alert {
        margin: 0.1rem 0;
    }

    .daterange {
        background-color: $color-background-notpro !important;
    }
}
