

.choose-billing-address {
    text-align: center;

    .address-card {
        width: fit-content;
    }
}

