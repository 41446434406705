.rin-skill-rating-modal-body-container {
	.rin-skill-rating-modal-titles-container {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		row-gap: 0.75rem;
		margin: 0 1rem 1rem 1rem;

		.rin-skill-rating-modal-title {
			font-size: $font-size-h3;
			line-height: $font-height-h3;
			font-weight: $font-weight-h3;
		}

		.rin-skill-rating-modal-subtitle {
			font-size: $font-size-h5;
			line-height: $font-height-h5;
			font-weight: $font-weight-h5;
		}
	}

	.rin-skill-rating-modal-options {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 0.5rem;

		.rin-card {
			width: auto;
			max-width: 12rem;
			padding-bottom: 0.875rem;
			justify-content: space-between;
			align-items: center;
			gap: 0.5rem;

			.rin-type-label {
				font-size: 0.875rem;
				padding: 4px 5px;
				margin-top: 0.25rem;
			}

			.icon-star {
				font-size: 1.75rem;
				color: $color-text-premium;
			}

			.rin-card-body {
				font-size: $font-size-h6;
				line-height: $font-height-h6;
				font-weight: $font-weight-h6;

				white-space: unset;
				margin-bottom: 0.5rem;
				text-align: center;
			}
		}
	}
}
