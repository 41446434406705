﻿.teamleague-header {
    @media screen and (min-width: 1024px) {
        margin-bottom: 2rem;
    }

    .rin-btn-type-primary.router-link-exact-active {
        color: #ffffff !important;
        background: #2f3f7d !important;
        border-color: #ffffff !important;
    }

    .teamleague-menu, .menu-mobile {
        a {
            margin-bottom: 5px;
        }
    }

    span.icon.icon-v {
        transform: rotate(270deg);
        font-size: 0.6em;
        transform-origin: center center;
        transition-property: transform;
        transition-duration: 0.3s;

        &.collapsed {
            transform: rotate(360deg);
        }
    }

    .collapse-body {
        max-height: 0;
        transition: max-height 0.35s ease-out;
        overflow: hidden;
        position: relative;
    }

    .collapse-body-collapsed {
        max-height: 22.5rem;
        transition: max-height 0.35s ease;
        overflow: hidden;
        position: relative;
    }

    @media (max-width:767px) {
        .event-menu ul li {
            border: 1px solid #f1f1f1;
        }

        .nav-justified > li > a {
            margin-bottom: 0px !important;
        }

        .event-menu #teamleague-event-menu-btn {
            width: 100%;
        }
    }

    .teamleague-menu-mobile {
        display: flex;
        justify-content: center;

        .mobile-menu-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .teamleague-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (max-width:767px) {
        .teamleague-menu {
            display: none;
        }
    }

    @media (min-width:768px) {
        .teamleague-menu-mobile {
            display: none;
        }
    }

    .icon-v {
        font-size: 0.5rem;
    }

    .collapse-toggle {
        button {
            width: 100%;
        }
    }

    .disabled-custom {
        opacity: 0.5;
        pointer-events: none;
        touch-action: none;
    }
}
