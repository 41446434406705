@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

/* TODO: Tokenize breakpoints */

.calendar {
    padding-bottom: 30px;
    background-color: var(--color-background-secondary-dark-1);

    .streaming {
        animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }

    .wrap {
        overflow: visible;

        & > h3 {
            font-size: $font-size-h3;
            line-height: $font-height-h3;
            font-weight: $font-weight-h3;
            margin-left: 0.6125rem;
            margin-bottom: 0;
        }

        .calendar-filter-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .rin-dropdown-container {
                flex: 1;

                .rin-dropdown-toggle {
                    width: 100%;
                }
            }

            .countries-input {
                &:hover {
                    background-color: white !important;
                }
            }

            .date-row {
                display: flex;
                align-items: center;

                small {
                    color: var(--color-text-secondary);
                    font-size: 10px;
                    font-weight: 600;
                    flex: 3;
                }

                input {
                    flex: 9;
                }
            }

            .rin-option-super-admin {
                background: $color-background-warning-light-1;
                color: var(--color-text-primary);
            }
        }
    }

    &Tile {
        padding: 4px;
        font-size: 12px;
        font-weight: 400;

        .rin-card {
            @media screen and (max-width: 1024px) {
                padding: 0.5rem;
            }

            .rin-card-body {
                white-space: unset;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 2px 0;
        }

        .event-sport-icon {
            font-size: 0.875rem;
        }

        .event-link {
            font-size: $font-size-h4;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
            display: flex;
            text-decoration: underline;
            color: #2F3F7D;
        }

        .box {
            margin: 0;
            padding: 16px 38px;

            @media screen and (max-width: 1024px) {
                padding: 5px;
            }
        }

        .callImg {
            padding: 0 5px;
            overflow: hidden;
            //@include lg {
            @media screen and (max-width: 1024px) {
                padding: 0 2px;
            }

            a {
                float: left;
                width: 100%;
                margin: 0;
                padding: 0;
            }

            img {
                width: 100%;
                margin: 0;
                padding: 0;
                max-height: 125px;
                max-width: 125px;
                object-fit: cover;
                border-radius: 8px;

                @media screen and (max-width: 1024px) {
                    object-fit: cover;
                }
            }
        }

        .callDesc {
            p {
                margin: 0;
                font-size: $font-size-h5;
                font-weight: 500;
            }

            a {
                display: inline-block;
                word-break: break-word;
            }

            h3 {
                width: 100%;
                margin-bottom: 4px;
                font-size: $font-height-h3;
                cursor: pointer;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: baseline;
                gap: 0.25rem;

                .btn {
                    padding: 0 5px 1px;
                    font-size: 9px;
                    font-weight: 700;
                }
            }

            .options {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 4px;
                width: 100%;
                margin: 0;
                padding: 0;
                margin-bottom: 0;
                clear: both;

                .sport {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1;
                    vertical-align: text-bottom;

                    .icon {
                        float: left;
                    }
                }

                .rin-badge {
                    border-radius: 10px;
                    padding: 1px 10px;
                    font-size: 10px;
                    font-weight: 700;

                    &.event-tournament {
                        color: var(--color-text-primary);
                        background-color: $color-background-success;
                    }

                    &.event-club-league {
                        color: var(--color-text-primary);
                        background: $color-background-info;
                    }

                    &.event-team-league {
                        color: var(--color-text-primary);
                        background: var(--color-background-primary);
                    }
                }
            }

            .country {
                a {
                    display: inline-block;
                    text-decoration: none;
                }

                img {
                    display: inline-block;
                    width: 14px;
                    height: 11px;
                    margin: 0;
                    padding: 0;
                    margin-right: 4px;
                    vertical-align: baseline;
                }
            }
        }
    }

    .rin-filter-tags-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.25rem;

        button {
            justify-content: space-between;
        }
    }
}
