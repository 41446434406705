@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-free-trial-end-content {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin-bottom: 0;
	}
	h4 {
		font-size: $font-size-h4;
		line-height: $font-height-h4;
		font-weight: $font-weight-h4;
		margin-bottom: 0;
	}
	p {
		font-size: $font-size-body;
		line-height: $font-height-body;
		font-weight: $font-weight-body;
		margin-bottom: 0;
	}
	.underline {
		text-decoration: underline;
	}

	.rin-free-trial-end-title {
		display: flex;
		flex-flow: row nowrap;
		column-gap: 0.5rem;
		margin-bottom: 0.5rem;

		.rin-type-label {
			display: table;
			margin: auto 0;
			font-size: 1rem;
			line-height: 1rem;
		}
	}

	.rin-free-trial-end-body {
		margin-top: 4.5rem;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		.upgrade-to-pro-button {
			display: block;
			margin-top: 1.75rem;
			margin-bottom: 1rem;
		}

		h4 {
			margin-top: 1rem;
			margin-bottom: 2rem;
			cursor: pointer;
		}
	}
}
