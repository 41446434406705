﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-dropdown-pagination {
    display: flex;
    gap: 1rem;
    align-items: center;

    .rin-dropdown-pagination-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
}

.rin-pagination-page-picker {
    ul {
        display: flex;
        gap: .5rem;
        list-style: none;
        justify-content: center;
        margin: 0;
        padding: 0;

        li {
            font-size: 12px;
            line-height: 20px;
            cursor: pointer;

            &.selected, &:hover {
                font-weight: 700;
            }

            &.rin-page-picker-arrow {
                margin: 0 .25rem;
            }
        }
    }
}