﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';




.private-match-campaign-container {
    font-size: $font-size-h4;


    img {
        max-height: 400px;
    }

    .h1 {
        font-weight: $font-weight-h1;
        font-size: $font-size-h1;
    }

    @media screen and (max-width:1023px) {
        flex-direction: column !important;

        .campaign-text {
            text-align: center;

            .d-flex {
                justify-content: center;
            }
        }
    }

    @media screen and (min-width:1024px) {

        .campaign-text {
            padding-right: 3rem;
        }

        flex-direction: row !important;
    }
}
