﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.tournament-times-container {
    .play-dates.selected {
        border: 1px solid rgb(47, 63, 124);
        background-color: rgb(47, 63, 125);
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 5px;
    }

    .play-dates {
        border: 2px solid rgb(47, 63, 125);
        background-color: #f3f3f3;
        color: rgb(47, 63, 125);
        text-align: center;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        padding: 0.1rem;
        height: 5rem;
        width: 3.125rem;
        cursor: pointer;
    }

    .play-dates > div {
        font-size: 1rem;
    }

    .info-header {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
    }

    .dates-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0.5rem;
        gap: 0.2rem;
    }

    .up-btn {
        .icon-v {
            transform: rotate(180deg);
        }
    }

    .dates-wrapper.non-clickable {
        display: flex;
        justify-content: center;
    }

    .tab-content {
        label {
            font-size: $font-size-h4;
            line-height: $font-height-h4;
            font-weight: $font-weight-not-so-bold;
        }
    }

    .rin-dropdown-toggle {
        width: 100%;
    }

    .save-btn {
        display: flex;
        flex-direction: column;

        button {
            width: 100%;
        }
    }
}


.time-settings-wrapper {
    /*background-color: $color-background-secondary-dark-2;*/
    .settings-title {
        font-size: $font-size-h2;
        font-weight: $font-weight-h2;
        line-height: $font-height-h2;
        display: flex;
        justify-content: center;
    }

    .settings-body {
        .settings-list {
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 15px;
            overflow: hidden;
            margin-top: 1.5rem;

            ul {
                padding: 10px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding: 10px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    display: grid;
                    /*grid-template-columns: repeat(3, 1fr);*/
                    grid-template-columns: 2fr 1fr 2fr;
                    justify-items: center;
                    /*   display: flex;
                justify-content: space-between;
                align-items: center;*/
                    cursor: pointer;

                    button {
                        justify-self: right;
                    }

                    .setting-name {
                        font-size: $font-size-body;
                        line-height: $font-height-body;
                        font-weight: $font-weight-body;
                        justify-self: baseline;
                        white-space: normal;
                    }

                    .setting-value {
                        font-size: $font-size-body;
                        line-height: $font-height-body;
                        font-weight: $font-weight-not-so-bold;
                        justify-self: left;
                    }

                    .icon-v {
                        transform: rotate(270deg);
                        justify-self: right;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(47, 63, 125, 0.1);
                    }
                }
            }
        }

        .rin-toggle {
            justify-self: right;

            .vue-js-switch:not(.toggled) {
                .v-switch-core {
                    background-color: $color-background-tertiary !important;

                    .v-switch-button {
                        background-color: $color-background-success !important;
                        height: 1.225rem !important;
                        width: 1.375rem !important;
                    }
                }
            }

            .vue-js-switch.toggled {
                .v-switch-core {
                    background-color: $color-background-success !important;

                    .v-switch-button {
                        background-color: #ffffff !important;
                        height: 1.225rem !important;
                        width: 1.375rem !important;
                    }
                }
            }
        }
    }
}