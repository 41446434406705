@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-body-modal-open .rin-modal-wrapper {
	overflow-x: hidden;
	overflow-y: auto;

	@media screen and (max-width: 1024px) {
		height: -webkit-fill-available;
		-webkit-overflow-scrolling: touch;
	}
}

.rin-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100vh;
    outline: 0;
    opacity: 1;
    transition: opacity 0.15s linear;

    .rin-modal-size-s {
        width: auto;
        margin: 0.5rem;
        // @media screen and(min-width: 576px) {
        max-width: 19rem;
        margin: 1.75rem auto 4.5rem;
        // }
    }

    .rin-modal-size-m {
        width: auto;
        margin: 0.5rem;
        // @media screen and(min-width: 576px) {
        max-width: 32rem;
        margin: 1.75rem auto 4.5rem;
        // }
    }

    .rin-modal-size-l {
        width: auto;
        margin: 0.5rem;
        // @media screen and(min-width: 576px) {
        max-width: 32rem;
        margin: 1.75rem auto 4.5rem;
        // }

        @media screen and (min-width: 1024px) {
            max-width: 50rem;
            margin: 1.75rem auto 4.5rem;
        }
    }

    .rin-modal-size-fullv-s {
        width: auto;
        max-width: 25rem;
        margin: 1.75rem auto 4.5rem;

        @media screen and (max-width: 1024px) {
            max-width: 25rem;
            margin: 0 auto;
            border-radius: 0.5rem;
            min-height: 100%;

            .rin-modal-content {
                overflow-y: auto;
                height: 90vh !important;
            }
        }
    }

    .rin-modal-size-fullv-l {
        width: auto;
        max-width: 90%;
        margin: 1.75rem auto 4.5rem;

        @media screen and (max-width: 1024px) {
            max-width: 50rem;
            margin: 0 auto;
            border-radius: 0.5rem;
            min-height: 100%;

            .rin-modal-content {
                overflow-y: auto;
                height: 90vh !important;
            }
        }
    }

    .rin-modal-size-full-window {
        width: auto;
        /*max-width: 50rem;*/
        height: 100%;
        margin: 0 auto;
        border-radius: 0.5rem;
        min-height: 100%;


        .rin-modal-content {
            overflow-y: hidden;
            padding: 0 !important;

            .rin-modal-header {
                margin: 0;

                h4 {
                    margin-bottom: 0;
                }
            }
        }
    }

    .rin-modal-size-prompt {
        margin: 0.5rem;
        max-width: 25rem;
        margin: 1rem auto 4rem;
        position: relative;
        top: 35%;
    }

    .rin-modal-size-prompt-bottom {
        width: auto;
        margin: 0.5rem;
        max-width: 25rem;
        margin: 1.75rem auto 4.5rem;
    }

    .rin-modal {
        position: relative;
        border-radius: 1rem;
        padding: 1rem;

        .rin-modal-content {
            padding: 1rem 1rem 0;

            .rin-modal-header {
                margin-bottom: 2rem;

                h4 {
                    margin-bottom: 0;
                }
            }

            .rin-modal-footer {
                display: flex;
                justify-content: flex-end;
                gap: 0.5rem;
                margin-top: 2rem;
            }
        }

        &.rin-modal-secondary {
            background-color: var(--color-background-secondary);
        }

        .icon-close {
            position: absolute;
            right: 2rem;
            top: 2rem;
            cursor: pointer;
        }
    }
}

.rin-modal-wrapper-over-bottom-nav {
	z-index: 9999999 !important;

	.rin-modal-size-prompt-bottom {
		position: absolute !important;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto auto 0 !important;
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}

.rin-modal-backdrop {
	opacity: 0.5;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
}

.rin-modal-no-padding {
	.rin-modal .rin-modal-content {
		@media screen and (max-width: 1024px) {
			padding: 0;
		}
	}
}
