@import './components/buttons.scss';
@import './components/dropdown.scss';
@import './components/base-checkbox.scss';
@import './components/base-collapsible.scss';
@import './components/base-datatable.scss';
@import './components/nav/navbar.scss';
@import './components/nav/navitem.scss';
@import './components/nav/navbar-scroll-arrow.scss';
@import './components/nav/topnav.scss';
@import './components/nav/bottomnav.scss';
@import './components/breadcrumbs/page-breadcrumbs.scss';
@import './components/breadcrumbs/breadcrumb-entry.scss';
@import './components/profile/opponents.scss';
@import './components/profile/profile-info.scss';
@import './components/ask-for-consent.scss';

@import './components/profile/americano.scss';
@import './components/profile/highest-skill-jump.scss';
@import './components/profile/profile-rankings.scss';
@import './components/profile/player-ranks-table.scss';
@import './components/profile/profile-events.scss';
@import './components/profile/profile-manage.scss';

@import './components/ranking/ranking.scss';

@import './components/admin-panel/teamleague-header.scss';

@import './components/admin-panel/tournament-header.scss';
@import './components/admin-panel/tournament-controls.scss';
@import './components/admin-panel/tournament-footer.scss';
@import './components/upgrade-to-pro-button.scss';
@import './components/organization/SendEmailToClubMembers.scss';

@import './components/admin-panel/logo-images.scss';

@import './components/search.scss';
@import './components/cards.scss';
@import './components/alerts.scss';
@import './components/popover.scss';
@import './components/toggle.scss';
@import './components/type-label.scss';
@import './components/carousel.scss';
@import './components/search-history.scss';
@import './components/search-results-group.scss';
@import './components/base-filter-button.scss';
@import './components/modal.scss';
@import './components/pagination.scss';
@import './components/page-top-bar/page-top-bar.scss';

@import './components/forms/filter-form-wrapper.scss';
@import './components/forms/datepicker.scss';

@import './components/modals/base-modal-wrapper.scss';

@import './components/monetization/modal-pro-players.scss';
@import './components/modals/trial-modal.scss';

@import './components/monetization/choose-billing-address.scss';
@import './components/monetization/star-indicator.scss';
@import './components/monetization/freemium-campaign.scss';

@import './components/organisation/OrganisationCalendar.scss';

@import './components/modals/modal-skill-rating.scss';

@import './components/modals/premium-tournament.scss';

@import './components/register/register-modal.scss';

@import './components/common/confirm-create-event.scss';
@import './components/settings/settings.scss';
@import './components/tournament/tournamen-info.scss';

@import './views/calendar.scss';
@import './views/profile.scss';
@import './views/bookmarks.scss';
@import './views/matches.scss';
@import './views/iadmin.scss';
@import './views/pricing.scss';
@import './views/messenger.scss';
@import './views/subscription-success.scss';
@import './views/private-match-create.scss';
@import './views/private-match-campaign.scss';
@import './views/newsletter-campaign.scss';
@import './views/pro-players-campaign.scss';
@import './views/freemium-campaign.scss';
@import './views/free-trial-end-campaign.scss';
@import './views/pwa-campaign.scss';
@import './views/private-match-info.scss';
@import './views/draws-settings.scss';
@import './views/schedule-settings.scss';
@import './views/referee-board.scss';

@import './views/AdminPanel/Tournament/close-sign-in.scss';
@import './views/AdminPanel/Tournament/create-edit.scss';
@import './views/AdminPanel/Tournament/match-settings.scss';
@import './views/AdminPanel/Tournament/start-finish.scss';
@import './views/AdminPanel/Tournament/classes-step.scss';
@import './views/AdminPanel/Tournament/tournament-times.scss';
@import './views/AdminPanel/Tournament/tournament-enter-results.scss';

@import './views/AdminPanel/TeamLeague/TeamLeagueCreateEdit.scss';
@import './views/AdminPanel/TeamLeague/TeamLeagueTeams.scss';

@import './views/AdminPanel/shared/auto-add.scss';
@import './views/AdminPanel/shared/auto-add-fake.scss';


@import './views/UserPart/OrganisationIndexInfo.scss';
@import './views/UserPart/OrganisationIndexCalendar.scss';

@import './modal-views/base-body-modal.scss';
@import './modal-views/create-modal.scss';
@import './modal-views/copy-tournament-modal.scss';

@import './common/scroll.scss';
@import './common/spinner.scss';

html,
body {
    font-size: 16px;
    /* themes */
    .theme-light {
        --color-text-primary: #{$color-text-primary};
        --color-text-secondary: #{$color-text-secondary};
        --color-background-primary: #{$color-background-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-dark {
        --color-text-primary: #{$color-text-primary};
        --color-text-secondary: #979fbe;
        --color-background-primary: #2a345e;
        --color-background-primary-dark-1: #232737;
        --color-background-secondary: #1F1F1F;
        --color-background-secondary-dark-1: #101010;
    }

    .theme-4caf50 {
        --color-text-secondary: #4caf50;
        --color-background-primary: #4caf50;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-c62828 {
        --color-text-secondary: #c62828;
        --color-background-primary: #c62828;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-ff5722 {
        --color-text-secondary: #ff5722;
        --color-background-primary: #ff5722;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-ffc107 {
        --color-text-secondary: #ffc107;
        --color-background-primary: #ffc107;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-3949ab {
        --color-text-secondary: #3949ab;
        --color-background-primary: #3949ab;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-03a9f4 {
        --color-text-secondary: #03a9f4;
        --color-background-primary: #03a9f4;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-9e9e9e {
        --color-text-secondary: #9e9e9e;
        --color-background-primary: #9e9e9e;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }

    .theme-000000 {
        --color-text-secondary: #000000;
        --color-background-primary: #000000;
        --color-text-primary: #{$color-text-primary};
        --color-background-primary-dark-1: #{$color-background-primary-dark-1};
        --color-background-secondary: #{$color-background-secondary};
        --color-background-secondary-dark-1: #{$color-background-secondary-dark-1};
    }
}

body {
    .rin-admin-part {
        header,
        .rin-bottom-nav {
            background: $color-background-primary-admin;

            .topBar {
                background: $color-background-secondary-admin;
            }
        }

        footer {
            background: $color-background-secondary-admin-2;
        }

        h1 {
            color: $color-text-primary-admin;
        }
    }

    table {
        &.rin-table-responsive {
            @media screen and (max-width: 1024px) {
                display: block;
                overflow-x: auto;
            }
        }
    }

    footer {
        margin-top: auto;
        margin-bottom: 0;
        //position: relative; //for zindex
        //z-index: 1004; //bigger than the main body modal zindex. has to be visible even on dextop viewport
    }

    @media screen and (max-width: 1024px) {
        footer {
            display: none;
        }
    }

    &.rin-body-modal-open {
        height: 100vh;
        overflow: hidden;
    }

    .rin-alternative-bg {
        background: var(--color-background-secondary-dark-1);
    }
}
/*
    Used for positioning the Search component
    If causing regression, don't remove it, but fix with correct position
*/
.rin-main-content {
    position: relative;
    min-height: 31rem;

    @media screen and (max-width: 1024px) {
        bottom: 4.4375rem;
    }
}

#landingPageContainer {
    @media screen and (max-width: 1024px) {
        margin-top: 4.4375rem;
    }
}

.rin-table-scroll {
    display: block;
    overflow-x: auto;
}

.rin-adminpart-controls-dropdowns {
    .rin-dropdown-container {
        &:last-of-type {
            ul {
                left: unset;
                min-width: 300px;
            }
        }
    }
}

.rin-form-control {
    font-weight: 600 !important;
    color: #2f3f7d !important;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    background-color: white;
}

.regulations-toggle {
    cursor: pointer;
    padding-top: 10px;
}

.collapse-toggle {
    color: $color-text-info;
    cursor: pointer;
}

.rin-event-regulations.collapsed:before {
    content: '';
    width: 100%;
    height: 130px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 90px, $color-background-light);
}

.rin-event-regulations {
    span {
        white-space: unset !important;
    }
}

#banner-ad {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
}

#banner-ad:empty,
#anchor-ad:empty {
    display: none;
}

#anchor-ad {
    position: fixed;
    bottom: 71px;
    z-index: 1999; // one less than the base modal wrapper z-index
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads-offset {
    padding-bottom: 36px;
    background-color: var(--color-background-secondary-dark-1);
}

.row-club-organizer td, .row-club-organizer th {
    vertical-align: middle !important;
}

.row-club-organizer td img, .row-club-organizer th img {
    margin: 0;
    height: 43px;
}



/*START animation for navbar when scrolling up/down*/
header {
    .slide-enter-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: linear;
        -webkit-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
    }

    .slide-leave-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: linear;
        -webkit-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
    }

    .slide-enter-to, .slide-leave {
        max-height: 100px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
}
/*END animation for navbar when scrolling up/down*/