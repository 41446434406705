﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.fake-player-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 1.5rem 0 0.7rem 0;

    img.big {
        width: 278px;
        height: 302px;
    }

    img.small {
        width: 225px;
        height: 302px;
    }

    .fake-player-form {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        .input-error {
            background-color: $input-background-error;
            border-color: $color-border-danger;
        }

        div {
            margin-bottom: 0.5rem;
        }

        div:last-of-type {
            margin: 0 !important;
        }

        .fake-form-controls {
            display: flex;
            flex-direction: column;
            justify-content: center;

            div:first-of-type {
                display: flex;
                flex-direction: row;
                align-items: baseline;

                input {
                    margin: 0 0.5rem;
                }
            }

            div:last-of-type {
                display: flex;
                justify-content: center;
            }
        }
    }
}
