﻿@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';

.trial {
    text-align: center;

    @media screen and (max-width: 1024px) {
        margin: -1rem;
    }

    .trial-information-wrapper {
        .rin-card {
            .rin-card-title {
                margin-bottom: 2rem;
            }

            .rin-card-header {
                margin-bottom: 2rem;
            }
        }

        .trial-body-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.8rem;

            .or {
                margin: 0.5rem 0;
                padding-bottom: 0;
            }

            .no-card-needed {
                padding-bottom: 2rem;
            }

            .or, .no-card-needed {
                font-size: $font-height-button-s;
                line-height: $font-height-body;
                font-weight: $font-weight-card-info;
                font-style: italic;
            }

            .underlined {
                font-size: $font-size-h4;
                line-height: $font-height-h4;
                font-weight: $font-weight-h4;
                cursor: pointer;
            }
        }

        .trial-footer {
            white-space: pre-line;
            font-size: $font-size-h6;
            line-height: $font-height-h6;
            font-weight: $font-weight-h6;
        }
    }

    .header-intro-text {
        text-transform: uppercase;
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-h6;
        padding-bottom: 2rem;
    }

    .title-small {
        line-height: $font-height-h4;
        font-weight: $font-weight-h4;
        font-size: $font-size-h4;
        padding-bottom: 0.3rem;

        .pro {
            font-size: $font-size-h3 !important;
            padding: 0.3rem !important;
        }
    }

    .title-big {
        font-size: $font-size-h1;
        line-height: $font-height-h1;
        font-weight: $font-weight-h1;
    }

    .trial-description {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-h6;
        white-space: pre-line;
        font-style: italic;
        padding-bottom: 2rem;
    }

    .trial-success-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .rin-card-title {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .trial-success {
            white-space: pre-line;
            margin-bottom: 1rem;
        }

        .trial-footer {
            display: flex;
            justify-content: center;
        }

        .rin-card-header, rin-card-body, rin-card-footer {
            display: none;
        }
    }

    .trial-modal-subtitle {
        font-style: italic;
        font-size: 0.85rem;
        line-height: 0.725rem;
        font-weight: $font-weight-h6;
    }

    .trial-modal-bulletpoints {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-h6;
        list-style: none;
        padding: 0;
        white-space: initial;
        text-align: left;

        @media screen and (max-width: 1024px) {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
        }

        li {
            margin-bottom: 0.8125rem;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .icon-check {
            color: var(--color-text-primary);
            background: $color-background-pro;
            border-radius: 50%;
            padding: 0.125rem;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }

    .trial-modal-plans-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;

        @media screen and (max-width: 460px) {
            grid-template-columns: 1fr;
        }

        .rin-card {
            position: relative;
            background: var(--color-background-secondary-dark-1);
            border: 1px solid rgba($color: $color-background-primary, $alpha: 0.1);

            &.rin-popular-plan-container {
                border: 1px solid rgba($color: $color-background-primary, $alpha: 0.5);
            }

            .rin-card-title {
                position: absolute;
                top: -0.375rem;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
            }

            .rin-card-header {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                height: 2rem;

                span {
                    background: var(--color-background-primary);
                    color: var(--color-text-primary);
                    border-radius: 20px;
                    padding: 0.25rem 0.5rem;
                    display: table;
                    font-size: 0.75rem;
                }
            }

            .rin-card-body {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;

                .rin-pro-player-modal-plan-period {
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-h6;
                    margin-top: 1.25rem;
                }

                .rin-pro-player-modal-plan-cost {
                    font-size: $font-size-h2;
                    line-height: $font-height-h2;
                    font-weight: $font-weight-h2;

                    span {
                        font-size: $font-size-h5;
                        line-height: $font-height-h5;
                        font-weight: $font-weight-h5;
                    }
                }

                .rin-pro-player-modal-plan-breakdown {
                    font-size: $font-size-breadcrumbs;
                    line-height: $font-height-breadcrumbs;
                    font-weight: $font-weight-breadcrumbs;
                    min-height: 1rem;
                    margin-bottom: 0.5rem;
                }

                .cancel-any-time {
                    font-size: 11px;
                    line-height: 16px;
                    text-align: center;
                    color: var(--color-text-secondary);
                    margin-bottom: .5rem;
                }
            }
        }
    }

    .trial-modal-pricing-description {
        padding-bottom: 1rem;
        font-size: 0.75rem;
        font-weight: $font-weight-card-info;
    }
}