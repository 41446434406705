@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-filter-form-wrapper {
	margin-bottom: 1.25rem;

	.rin-filter-form-title {
		display: flex;
		flex-flow: row nowrap;
		gap: 0.25rem;
		margin-left: 0.75rem;
		cursor: pointer;
		align-items: center;
		margin-bottom: 1rem;

		font-size: $font-size-h5;
		line-height: $font-height-h5;
		font-weight: $font-weight-h5;

		.rin-filters-text {
			text-decoration: underline;
		}

		.icon {
			display: inline-block;
			font-size: 0.5em;
			position: relative;
		}
		.icon-arrow-down {
			transform-origin: center center;
			transition-property: transform;
			transition-duration: 0.3s;
		}

		.icon-arrow-up {
			transform-origin: center center;
			transition-property: transform;
			transition-duration: 0.3s;
			transform: rotate(-180deg);
		}
	}

	.rin-filter-form-content {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		gap: 0.75rem;
		align-items: center;

		@media screen and (max-width: 1024px) {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 1rem;

			.rin-dropdown-toggle {
				& > span:first-child {
					display: block;
					max-width: 80%;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}

		& > * {
			width: 100%;

			& > * {
				width: 100%;
			}
		}

		.rin-concrete-selection {
			& > button,
			input[name='date'] {
				background: var(--color-background-primary);
				color: var(--color-text-primary) !important;
			}
		}
	}

	.slide-down-enter-active,
	.slide-down-leave-active {
		max-height: 200px;
		overflow-y: hidden;
		transition: max-height 0.8s ease-in-out;
	}
	.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
		max-height: 0;
	}
}
