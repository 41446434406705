﻿@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-breadcrumbs-container {
	margin-bottom: 2rem;
	padding: 1.875rem 2.5rem 0;
	font-size: $font-size-breadcrumbs;
	line-height: $font-height-breadcrumbs;
	font-weight: $font-weight-breadcrumbs;
	letter-spacing: $font-letter-spacing-breadcrumbs;

	@media screen and (max-width: 1024px) {
		margin-bottom: 0.625rem;
		padding: 1.25rem 1rem 0;
		width: unset;
	}

	&>.rin-breadcrumb-entry:last-child {
		.rin-breadcrumb-separator {
			display: none;
		}
	}
	
}
