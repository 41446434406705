@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-dropdown-container {
    position: relative;

    & > .rin-dropdown-toggle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        //@TODO: tokenize those values
        //padding: 0 72px;

        .rin-dropdown-toggle-icon {
            //position: absolute;
            // @TODO: add right and top prop depending on sizing and import from basic tokens

            &::after {
                display: inline-block;
                font-family: 'icomoon';
                content: '\e91a';
                margin: auto;
                font-size: 0.5em;
                transform-origin: center center;
                transition-property: transform;
                transition-duration: 0.3s;
            }

            &.rin-dropdown-toggle-icon-open::after {
                transform: rotate(-180deg);
            }
        }
    }

    & > :last-child:not(.rin-dropdown-toggle) {
        position: absolute;
        z-index: 9;
        width: auto;
        max-width: 100%;
        max-height: 20rem;
        background: #f3f3f3;
        overflow: auto;
        list-style: none;
        padding: 0;
        border-radius: 0 0 12px 12px;
        left: 0;
        right: 0;
        filter: drop-shadow(0 0 18px rgba(0,0,0,0.25));


        li {
            padding: 5px 15px;
            cursor: pointer;
            color: #2f3f7c;

            &:not(.no-hover):hover {
                background: #2f3f7c;
                color: #fff;
            }

            font-size: 11px;
            font-weight: 700;
            white-space: nowrap;
        }
    }

    & > .rin-dropdown-list.up {
        bottom: 1em;
    }

    & > .rin-dropdown-list.right {
        right: inherit;
    }
}
