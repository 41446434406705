@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-filter-button {
	background: transparent;
	font-weight: bold;
	font-size: $font-size-filter;
	line-height: $font-height-filter;
	color: var(--color-text-secondary);
	padding: 2px 10px;
	white-space: nowrap;

	&.selected {
		border: 2px solid $color-border-secondary;
		border-radius: 3rem;
	}

	&:focus {
		outline: unset;
	}

	&:hover {
		color: var(--color-text-secondary);
	}
}
