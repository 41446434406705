﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.auto-add-container {
    .collapse-body {
        max-height: 0;
        transition: max-height 0.25s ease-out;
        overflow: hidden;
        position: relative;
    }

    .collapse-body-collapsed {
        max-height: 33.75rem;
        transition: max-height 1s ease;
        overflow: hidden;
        position: relative;
    }

    .back-btn {
        width: 5rem;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;

        a {
            gap: 0;
        }
    }

    .rin-dropdown-container {
        margin: 0.5rem 0;
    }

    .rin-card {
        margin-bottom: 1rem;
    }

    .title-container {
        .event-name {
            font-size: $font-size-h1;
            font-weight: $font-weight-not-so-bold;
            line-height: $font-height-h1;
        }

        div:last-of-type {
            margin-bottom: 0.5rem;
        }
    }

    .fake-wrapper {
        background-color: $color-background-light;
    }

    .real-players-wrapper {
        .rin-card-body {
            overflow: visible;
        }

        display: flex;
        flex-direction: row;
        justify-content: space-around
    }

    .real-player-search-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .short-input {
            width: 70% !important;
        }
    }

    .players-list {
        overflow-y: auto;

        .media-heading {
            font-size: $font-size-h4;
            line-height: $font-height-h4;
            font-weight: $font-weight-not-so-bold;
        }

        .btn-wrapper {
            display: flex;
            justify-content: end;
            gap: 0.3rem;
        }
    }

    .fake-player-form {
        div {
            font-weight: $font-weight-card-label;
        }
    }

    .rin-dropdown-container {
        ul {
            z-index: 99999;
        }
    }

    .class-dropdown-wrapper {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
    }
}

.rin-autoadd-team {
    .rin-team-details-wrapper {

        h3 {
            font-size: $font-size-h3;
        }
    }

    .rin-save-button-wrapper {
        margin-top: 2rem;

        button {
            width: 100%;
            border-radius: 5px;
        }
    }

    .event-info {
        margin-bottom: 0.5rem;
    }
}