﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';
.subscription-container {
    .subscription-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 5%;

        @media screen and (max-width: 480px) {
            margin-top: 30%;
        }


        @media screen and (max-width: 768px) and (min-width:481px) {
            margin-top: 15%;
        }

        @media screen and (max-width: 1024px) and (min-width:769px) {
            margin-top: 10%;
        }

        .title {
            font-size: $font-size-h1;
            font-weight: $font-weight-h1;
            line-height: $font-height-h1;
            color: $color-text-pro;
        }

        .subscription-message {
            font-size: $font-size-h6;
            font-weight: $font-weight-card-label;
            line-height: $font-height-h6;
            color: var(--color-text-secondary);
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
    }
}
