﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.upgrade-to-pro-button {
	background: var(--color-background-secondary);

	.rin-upgrade-to-pro-button-text {
		white-space: nowrap;
	}

	.rin-upgrade-to-pro-overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);

		p {
			margin-top: 1rem;
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: 500;
			text-align: center;
		}
	}
}
