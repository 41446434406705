﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-card {
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.25rem 1.25rem 1rem 1.25rem;
	border: 1px solid $color-border-tertiary;
	border-radius: 15px;
}

.rin-card-bg-primary {
	background: $color-background-light;
}

.rin-card-bg-secondary {
	background: var(--color-background-secondary);
}

.rin-card-bg-info {
	background: $color-background-info-light-2
}

.rin-card-bg-warning {
	background: $color-background-warning-light-2;
}

.rin-card-shadow {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.rin-card-border {
	border: 1px solid rgba(47, 63, 125, 0.1);
}

.rin-card-no-border {
	border: none;
}

.rin-card-header,
.rin-card-title,
.rin-card-body,
.rin-card-footer {
	max-width: 100%;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.rin-card-no-overflow-body {
	.rin-card-body {
		overflow: unset;
	}
}

/*mobile*/
@media screen and (max-width: 1024px) {

    .rin-card {
        padding: 1.25rem 0.75rem 1rem 0.75rem;
    }
}
