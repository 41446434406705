﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-pro-modal-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .icon-wrapper {
        color: $premium-tournament;
    }

    .icon-wrapper .icon-star {
        font-size: 2rem;
    }

    .text-wrapper {
        font-weight: 700;
    }
}