﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.rin-admin-page {
    padding-bottom: 1rem;

    &:not(.tn-in-create) {
        padding-top: 1rem;
    }

    @media screen and (max-width: 1024px) {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

.rin-tournament-create-edit {
    .rin-tournament-section-header {
        display: flex;
        gap: 1rem;

        .popover-wrapper {
            color: var(--color-text-secondary);

            p {
                font-weight: 400;
            }
        }
    }

    .rin-courts-dropdown-wrapper {
        ul {
            li {
                text-align: left;
            }
        }
    }
}

.rin-select-tournament-club-header {
    margin-bottom: 0.4rem;
    font-weight: 500;
}

.rin-select-tournament-club-location {
    font-weight: 500;
}

.rin-select-tournament-club-stripe {
    margin-top: 2rem;

    .rin-stripe-header {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: .5rem;
    }
}

.rin-tournament-create-send-invitations {
    margin-top: 1.5rem;

    .section-title {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 0;
    }

    .section-body {
    }

    .invitation-status-label {


        /*background-color: cadetblue;
        color: dodgerblue;
        font-weight: bold;
        font-style: italic;
        border-radius: 3px;*/


    }
}

.container-create-tournament {
    #rankings-search-result {
        .list-group-item {
            cursor: pointer;
        }
    }

    .quillWrapper #quill-container {
        .ql-editor {
            resize: vertical;
        }
    }
}

.container-create-tournament {
    .callendarSingle {
        cursor: pointer;
    }
}

.rin-check-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.container-create-tournament {
    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1rem;
    }

    h3 {
        font-size: 0.8rem;
    }
}

.addCourtTable {
    table {

        @media screen and (max-width: 1024px) {
            display: block;
            overflow-x: auto;
        }

        thead {
            tr {
                td {
                    background: white;
                    border-bottom: none;
                    border-top: none;
                }
            }
        }
    }

    .input-error {
        background-color: #fa9a9a !important;
        color: #a94442 !important;
        border-color: red !important;
    }
}

.rin-tournament-bottom-controls-wrapper {
    button {
        border-radius: 5px;
        width:100%;
    }
}