﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';




.rin-newsletter-campaing-wrapper {

    // mobile
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    // desktop
    @media screen and (min-width: 1025px) {
        flex-direction: row;
    }
}



.newsletter-campaign-container {
    font-size: $font-size-h4;

    img {
        max-height: 400px;
    }

    .h1 {
        font-weight: $font-weight-h1;
        font-size: $font-size-h1;
        margin-bottom: 0;
        padding-bottom: 0 !important;
    }
}
