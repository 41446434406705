﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

#arrow {
    visibility: hidden;
}

#arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

div[id^="tooltip"][data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

div[id^="tooltip"][data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

div[id^="tooltip"][data-popper-placement^='left'] > #arrow {
    right: -4px;
}

div[id^="tooltip"][data-popper-placement^='right'] > #arrow {
    left: -4px;
}

div[id^="tooltip"] {
    background: var(--color-background-secondary);
    padding: 0.5rem;
    font-size: $font-size-h5;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 0.625rem;
    display: none;
    z-index:5;
}

div[id^="tooltip"][data-show] {
    display: block;
}
