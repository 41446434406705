﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';
.copy-tournament-wrapper {
    .alert-wrapper {
        margin: 1rem;
    }

    .name-wrapper {
        margin: 1rem;

        h1 {
            font-size: $font-size-h2;
            line-height: $font-height-h2;
        }

        .rin-alert-danger {
            background: unset !important;
            text-align: center;
        }
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
}