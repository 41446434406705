﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

@media screen and (max-width:1023px) {
}

@media screen and (min-width:1024px) {
}

.rin-matches-settings {
    .rin-matches-settings-buttons-wrapper {
        display: flex;
        gap: 0.5rem;
    }

    .rin-schedule-modal-header {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .rin-schedule-table {
        display: grid;
        grid-template-columns: 1fr 4fr 3fr 3fr 4fr;
        gap: 1rem;

        .rin-table-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 0.3rem;

            &.referee-item {
                flex-direction: column;
                gap: 1rem;
            }

            &.rin-table-header {
                font-weight: bold;
                font-size: 16px;
                border-bottom: 1px solid #ccc;
            }

            .rin-label-scheduled {
                background-color: green;
                padding: 3px 8px;
                color: white;
                border-radius: 5px;
            }

            .rin-label-not-scheduled {
                background-color: red;
                padding: 3px 8px;
                color: white;
                border-radius: 5px;
            }
        }
    }

    .rin-referee-modal-filters {
        display: flex;

        .rin-referee-modal-left {
            flex: 1;
            padding: 1rem;
        }

        .rin-referee-modal-right {
            flex: 1;
            padding: 1rem;
        }

        .rin-referee-class-filter-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: 1rem;
        }
    }
}