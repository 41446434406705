﻿@import '../../../tokens/fonts.scss';

.rin-tournament-close-sign-in {
    overflow: visible;

        @keyframes slide {
            0% {
                transform: translateX(-100%);
            }

            100% {
                transform: translateX(100%);
            }
        }

        .close-sign-in-button {
            margin-top: 0.5rem;

            button {
                width: 100%;
            }

            a.rin-btn-type-primary {
                width: 100%;
            }
        }

        .ranking-points-wrapper {
            display: flex;
            justify-content: flex-start;

            .table-title {
                font-size: $font-size-h3;
                font-weight: $font-weight-not-so-bold;
            }

            .icon-info-circle {
                font-size: $font-size-h3;
            }

            .tooltip-wrapper {
                max-width: 200px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                div {
                    font-size: $font-size-note;
                    text-align: center;
                }

                h4 {
                    margin-bottom: 0.625rem !important;
                    font-size: $font-size-h4;
                }
            }
        }

        .rin-dropdown-container {
            ul {
                // TODO: Remove when table is reworked to css grid
                max-height: 12rem !important;
                overflow-x: hidden !important;
                top: 25px;
                left:unset !important;
                right:unset !important;
                max-width:20rem !important;
            }
        }

        .rin-close-signin-table-wrapper {
            .rin-header, .rin-body .rin-row {
                display: grid;

                div {
                    font-size: 12px;
                    text-align: center;
                    font-weight: 700;

                    &.rin-xs-hidden {
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }

            .rin-body {
                .rin-row {
                    gap: .5rem;
                    background-color: #eaebf2;
                    margin-bottom: 10px;
                    border-radius: 15px;
                    padding: 10px 7px;

                    &> div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &.rin-tournament-levels {
                .rin-header, .rin-body .rin-row {
                    grid-template-columns: 2fr repeat(6, 1fr);

                    @media screen and (max-width: 1024px) {
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }

            &.rin-teamleague-levels {
                .rin-header, .rin-body {
                    grid-template-columns: 2fr repeat(5, 1fr);
                }
            }
        }
}

.rin-closesignin-levels-selecting {
    .show-level-standing {
        cursor: pointer;
    }

    .rin-dropdown-container {
        display: flex;
        justify-content: center;
    }

    .tooltip-wrapper {
        max-width: 200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tooltip-wrapper {
        div {
            font-size: 0.6875rem;
            text-align: center;
        }
    }

    .tooltip-wrapper {
        h4 {
            margin-bottom: 0.625rem !important;
            font-size: 0.875rem;
        }
    }
}
