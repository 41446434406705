@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-pwa-campaign-container {
    .rin-modal {
        .rin-modal-content {
            .rin-modal-header {
                margin-bottom: 0.5rem;
            }

            .rin-modal-footer {
                margin-top: 0;
            }

            .icon-close {
                top: 1rem;
                right: 1rem;
            }

            h3 {
                font-size: $font-size-h3;
                line-height: $font-height-h3;
                font-weight: 700;
                color: $color-text-dark-0;
                margin-bottom: 1.5rem;
            }

            h4 {
                font-size: $font-size-h4;
                line-height: $font-height-h4;
                font-weight: 400;
                color: $color-text-dark-0;
                margin: 0;
            }

            h6 {
                font-size: $font-size-h6;
                line-height: $font-height-h6;
                font-weight: 400;
                color: $color-text-gray-0;
                margin: 0;
            }

            .rin-pwa-dont-show-again {
                font-weight: $font-weight-body;
                font-size: $font-size-body;
                line-height: $font-height-body;
                color: $color-text-info-1;
                cursor: pointer;
            }
        }
    }

    .rin-android-pwa-popup {


        .rin-pwa-body {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;



            .rin-pwa-description {
                display: flex;
                align-items: center;

                div h4 {
                    font-weight: $font-weight-not-so-bold;
                }

                div {
                    padding-left: 1rem;
                }
            }

            button {
                height: 1.6rem;
            }
        }
    }

    .rin-ios-change-browser-popup {
        .rin-modal {
            background-color: #efeff1;
        }

        .rin-modal-content {
            color: $color-text-dark-0;
        }

        .rin-pwa-header {
            border-bottom: 1px solid #e4e5ed;
            display: flex;
            justify-content: start;
            align-items: center;

            img {
                width: 2.2rem;
                margin-bottom: 0.5rem;
            }

            h3 {
                margin-bottom: 0.5rem;
                padding-left: 0.5rem;
                font-family: "Roboto", sans-serif;
            }
        }

        .rin-pwa-body {
            margin-top: 1.375rem;
            margin-bottom: 0.5rem;
            font-family: 'Arial';
            font-weight: $font-weight-body;
            font-size: $font-size-body;
            line-height: $font-height-body;

            p {
                margin-bottom: 1.375rem;
            }

            div {
                display: flex;
                align-items: center;
                padding-bottom: 0.5rem;

                img {
                    padding-left: 0.5rem;
                }
            }
        }

        .rin-pwa-dont-show-again {
            border-top: 1px solid #e4e5ed;
            padding-top: 0.5rem;
        }
    }

    .rin-ios-pwa-popup {
        .rin-modal {
            background-color: #efeff1;
        }

        .rin-modal-content {
            color: $color-text-dark-0;
        }

        .rin-pwa-header {
            display: flex;
            justify-content: start;
            align-items: center;
            border-bottom: 1px solid #e4e5ed;

            img {
                width: 2.2rem;
                margin-bottom: 0.5rem;
            }

            h3 {
                margin-bottom: 0.5rem;
                padding-left: 0.5rem;
                font-family: "Roboto", sans-serif;
            }
        }

        .rin-pwa-body {
            margin-top: 1.375rem;
            margin-bottom: 0.5rem;
            font-family: 'Arial';
            font-weight: $font-weight-body;
            font-size: $font-size-body;
            line-height: $font-height-body;


            p {
                margin-bottom: 1.375rem;
            }

            div {
                display: flex;
                align-items: center;
                padding-bottom: 0.5rem;

                img {
                    padding-left: 0.5rem;
                }
            }
        }


        .rin-pwa-dont-show-again {
            border-top: 1px solid #e4e5ed;
            padding-top: 0.5rem;
        }
    }

    .rin-android-change-browser-popup {
        .rin-pwa-body {
            margin-top: 1.375rem;
            margin-bottom: 1.25rem;
            font-weight: $font-weight-body;
            font-size: $font-size-body;
            line-height: $font-height-body;
            color: $color-text-dark-0;

            p {
                margin-bottom: 1rem;
            }

            .rin-pwa-description {
                display: flex;
                align-items: center;
                margin-bottom: 1.375rem;

                div h4 {
                    font-weight: $font-weight-not-so-bold;
                }

                div {
                    padding-left: 1rem;
                }
            }
        }
    }
}
