﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

@media screen and (max-width:1023px) {
}

@media screen and (min-width:1024px) {
}

.draws-settings {

    .preview-draws-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 0.4rem;
        margin-bottom: 2rem;
    }

    .class-dropdown-wrapper {
        display: flex;
        align-items: center;

        .class-dropdown-title {
            margin-right: 2rem;
        }
    }

    .participants-count-wrapper {
        display: flex;

        div {
            margin-right: 2rem;
        }
    }

    .draw-types-wrapper {
        .draw-type-row {
            display: flex;
            gap: 10px;
            margin: 5px 0;

            &.rin-drawssettings-plates-wrapper {
                padding-left: 1.5rem;

                .rin-dropdown-container {
                    flex: 1;

                    button {
                        width: 100%;
                    }
                }

                .rin-flex-2 {
                    flex: 2;
                }
            }

            .rin-draw-type-name {
                min-width: 12rem;
            }

            .rin-popover-content {
                .rin-draws-popover-img-wrap {
                    float: left;
                    margin-right: 10px;
                }

                .rin-draws-popover-text {
                    min-height: 100px;
                    max-width: 300px;
                }
            }
        }
    }

    .rin-advanced-settings-wrapper {
        margin-top: 1.5rem;

        .rin-draw-settings-title {
            font-weight: 700;
            margin-bottom: 1.5rem;
        }
    }

    .rin-generate-buttons-wrapper {
        margin-top: 2rem;

        button {
            width: 100%;
            border-radius: 5px;
        }
    }

    > .row {
        > .col {
            padding: 0;
        }
    }
}

.rin-draws-wizard-wrapper {
    .rin-draws-wizard-row {
        display: flex;
        gap: 15px;
        align-items: center;
        margin-bottom: .25rem;
    }
}

.rin-input-disabled {
    color: rgba(47, 63, 125, 0.5) !important;
    pointer-events: none;
}

.rin-rr-settings-main-text-wrapper {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 0.5rem;

    .rin-rr-settings-main-text {
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        margin: 0;

        small {
            font-weight: 700;
            font-size: 12px;
            line-height: 24px;
        }
    }
}

.rin-round-robin-settings-wrapper {
    padding: 2rem 0 2rem;
    display: flex;

    &.team-settings-wrapper {
        flex-wrap: wrap;

        .rin-rr-section {
            flex-basis: 50%;
        }
    }

    .rr-settings-separator {
        margin: 1rem 0;
        font-size: 26px;
        padding-left: 8rem;
    }

    .rin-rr-section {
        flex: 1;
        padding: 0 1rem 1.5rem 0;

        .rin-rr-box {
            background: #E4E5ED;
            border-radius: 15px;
            padding: 1.5rem;
            width: fit-content;
            width: -moz-fit-content;

            .rin-rr-box-text {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 8px;
            }

            .rr-setting-dropdown-row {
                margin-bottom: 1rem;
            }

            .rin-rr-setings-row {
                button:not(.rin-dropdown-toggle), input {
                    background: #F8F8FA !important;
                }
            }
        }

        .rin-rr-setings-row {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 0.5rem;



            button:not(.rin-dropdown-toggle) {
                border-radius: 5px;
                padding: 10px;
            }

            input {
                font-weight: 800 !important;
                width: 40px;
                text-align: center;
                border: unset;
                background-color: #eaebf2;
                border-radius: 5px;
                color: #2f3f7d !important;
                padding: 5px 10px;
            }
        }
    }
}

.rin-wizzard-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    label {
        margin: 0;
        cursor: pointer;
    }
}

.rin-wizzard-loading {
    display: flex;
    gap: 1rem;
    padding: 2rem;
    align-content: center;
}
