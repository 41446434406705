﻿.freemium-why-free {
    margin: 1rem 0;

    .rin-card-body {
        white-space: normal;

        h3 {
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #2F3F7D;
        }

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #2F3F7D;
        }

        a {
            color: #00b9ff;
            text-decoration: underline;
        }
    }
}
@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';
.no-worries {
    display:flex;
    justify-content:center;
    .no-worries-wrapper {
        display:flex;
        flex-direction:column;
    }
}
.freemium-no-worries {
    display: flex;
    gap: 1rem;
    flex-direction:column;
    ul {
        margin-bottom: unset;
    }

    .title {
        font-size: $font-size-h3;
        line-height: $font-height-h3;
        font-weight: $font-weight-h3;
        text-align: center;
        margin-bottom: 0.2rem;
    }

    .rin-card {
        .rin-card-header {
            font-size: $font-size-h3;
            line-height: $font-height-h3;
            font-weight: $font-weight-h3;
            text-align: center;
            margin-bottom:0.8rem;
        }

        .rin-card-body {
            overflow: unset;
        }
    }


    .icon-check {
        background-color: var(--color-background-secondary);
        color: white;
        font-size: 12px;
        border-radius: 50%;
        padding: 0.125rem;
    }

    .first-card, .second-card {
        .list-wrapper {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
            padding-left: 0;

            .freemium-item {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                align-items:baseline;
                .item-text {
                    font-size: $font-size-body;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-not-so-bold;
                    white-space: normal;
                    min-width: 0;
                }


                .icon-check {
                    background-color: var(--color-background-primary);
                }
            }
        }
    }

    .second-card {
        .rin-card {
            background-color: $pro-player-card;
            border-color: $ri-gold;
        }

        .icon-check {
            background-color: $ri-gold;
        }
    }

    .pro {
        font-size: $font-size-h3 !important;
        padding: 0.3rem !important;
    }
}
