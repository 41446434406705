@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-register-sport {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin-bottom: 0;
	}

	.rin-dropdown-toggle {
		margin-top: 2rem;
	}
}
