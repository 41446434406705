﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.pro-campaign-container {
	font-size: $font-size-h4;
	gap: 1rem;

	@media screen and (max-width: 1024px) {
		gap: 0;
	}

	.rin-pro-player-modal-image-container {
		margin-top: -2rem;
	}

	img {
		max-width: 21rem;
		max-height: 19rem;

		@media screen and (max-width: 1024px) {
			max-width: 15.5rem;
			max-height: 12.5rem;
		}
	}

	.rin-campaign-title {
		color: $color-text-pro;
		font-weight: $font-weight-h1;
		font-size: $font-size-h1;
	}

	.rin-campaign-subtitle {
		color: $color-text-pro;
		font-weight: $font-weight-h1;
		font-size: 2.25rem;
		line-height: 2.5rem;

		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		.rin-type-label.pro {
			display: table;
			margin: auto 0;
			padding: 1px 5px;
		}
	}

	.campaign-text {
		text-align: center;

		.rin-card-body {
			.col-6 {
				padding: 0;

				.p-2 {
					font-weight: $font-weight-h6;
					font-size: $font-size-h6;
				}
			}
		}

		.rin-campaign-feature-others {
			font-weight: $font-weight-body-compressed;
			font-size: $font-size-body-compressed;
			font-style: italic;
		}
	}

	@media screen and (max-width: 1023px) {
		flex-direction: column !important;

		.campaign-text {
			.d-flex {
				justify-content: center;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		.campaign-text {
			padding-right: 3rem;
		}
	}

	.icon-check {
		color: var(--color-text-primary);
		background: var(--color-background-primary);
		border-radius: 50%;
		padding: 0.125rem;
		display: inline-block;
		margin-right: 0.5rem;
		font-size: $font-size-body;
	}

	button {
		background: $color-background-pro;
	}

	.rin-type-label.pro {
		font-weight: $font-weight-h1;
		font-size: $font-size-h1;
		line-height: $font-height-h1;
	}

	.campaign-text .not-so-bold {
		font-weight: $font-weight-not-so-bold;
	}

	.campaign-text .text-pro {
		font-weight: $font-weight-h5;
		font-size: $font-size-h5;
		font-style: italic;
		color: $color-text-pro;
	}

	.rin-campaign-footer {
		font-weight: $font-weight-h4;
		font-size: $font-size-h4;
	}
}
