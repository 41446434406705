@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.pricing-page {
    background-color: var(--color-background-secondary-dark-1);

    .rin-pricing-desktop-header {
        margin-top: 1.5rem;
        font-weight: 800;
        font-size: 1rem;
    }

    .rin-nav-bar-primary {
        background-color: var(--color-background-secondary-dark-1);
        box-shadow: unset;

        a {
            padding: 0.5rem 0.7rem;
            display: inline-block;
            font-weight: 700;
        }
    }

    .pricing-hero-title {
        @media (max-width: 1024px) {
            font-size: 1.6rem;
        }
    }

    .rin-pricing-card {
        margin-bottom: 23px;

		@media screen and (max-width: 1024px) {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 2.5rem;
		}

		.vueperslides__bullets {
			bottom: -1.5rem;
		}

        .rin-pricing-title {
            display: flex;
            justify-content: center;
            margin-top: 1.5rem;
			position: relative;
			z-index: 5;

			@media screen and (max-width: 1024px) {
				&:before {
					position: absolute;
					top: 1rem;
					overflow: hidden;
					left: 0;
					height: 1px;
					content: '\a0';
					background-color: var(--color-background-primary);
					width: 24%;
				}
	
				&:after {
					position: absolute;
					top: 1rem;
					overflow: hidden;
					right: 0;
					height: 1px;
					content: '\a0';
					background-color: var(--color-background-primary);
					width: 24%;
				}
			}

            .label-ghost {
                background: 0 0;
                color: #2e3d7e;
                display: inline-block;
                padding: 5px 30px;
                margin: 0;
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 20px;
                font-weight: 800;
                text-transform: none;
                border: 2px solid #2e3d7e;
                border-radius: 50px;
            }
        }

		.rin-card-header {
			@media screen and (max-width: 1024px) {
				white-space: unset;
			}

			.rin-pricing-header {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				color: #2F3F7D;
				margin-bottom: 2rem;
				margin-top: 1rem;
			}
		}

        .rin-pricing-body {

			&>.vueperslides {
				width: 100%;
			}

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-weight: 700;
                    line-height: 25px;
                    font-size: $font-size-body;
                    color: var(--color-text-secondary);

                    .icon {
                        background-color: var(--color-text-secondary);
                        border-radius: 50%;
                        font-size: 9px;
                        padding: 3px;
                        color: white;
                        font-weight: bold;
                        margin-right: .5rem;
                    }
                }
            }
        }

        .rin-pricing-label {
            font-weight: 600;
            font-size: 11px;
            line-height: 11px;
            color: #fff;
            border-radius: 3px;
            padding: 2px 3px;
            margin: 0 3px;
            transform: skew(-15deg, 0);
            display: inline-block;
            background: #EF3340;
            margin-bottom: 1rem;
        }

        .text-crossed {
            text-decoration: line-through;
        }

        .text-italic {
            font-style: italic
        }
    }

	.rin-pricing-tournament {
		.rin-pricing-title {
			@media screen and (max-width: 1024px) {
				&:before {
					width: 17%;
				}
	
				&:after {
					width: 17%;
				}
			}
		}
	}

	.rin-pricing-clubs {
		.rin-pricing-title {
			@media screen and (max-width: 1024px) {
				&:before {
					width: 26%;
				}
	
				&:after {
					width: 26%;
				}
			}
		}
	}

	.rin-pricing-federations {
		.rin-pricing-title {
			@media screen and (max-width: 1024px) {
				&:before {
					width: 18%;
				}
	
				&:after {
					width: 18%;
				}
			}
		}
	}

	.rin-pricing-onlinepayments {
		.rin-pricing-title {
			@media screen and (max-width: 1024px) {
				&:before {
					width: 12%;
				}
	
				&:after {
					width: 12%;
				}
			}
		}
	}

	.rin-pricing-sponsors {
		.rin-pricing-title {
			@media screen and (max-width: 1024px) {
				&:before {
					width: 20%;
				}
	
				&:after {
					width: 20%;
				}
			}
		}
	}


    .rin-pricing-tournament-premium {
		@media screen and (max-width: 1024px) {
			padding-left: 3rem;
			padding-right: 3rem;
			.rin-card-title {
				min-height: 2.85rem;
			}
		}
        background: $premium-tournament-card !important;
        border: 1px solid rgba(123, 97, 255, 0.2);
        border-radius: 15px;

        .title-wrapper {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 13px;
            margin-top: .5rem;
            flex-wrap: wrap;

            .icon-star {
                color: $premium-tournament;
                font-size: 1.3125rem;
                vertical-align: text-bottom;
            }

            .title-premium {
                font-style: italic;
                font-weight: 800;
                line-height: 24px;
                text-transform: uppercase;
                color: $premium-tournament;
            }

            .title-plan {
                font-weight: 800;
                line-height: 24px;
                text-align: center;
                color: var(--color-text-secondary);
            }
        }

        ul {
            list-style: none;
            margin: 0 0 16px;
            padding: 0;

            li {
                font-weight: 700;
                line-height: 25px;
                font-size: $font-size-body;
                color: var(--color-text-secondary);

                .icon {
                    background-color: $premium-tournament;
                    border-radius: 50%;
                    font-size: 9px;
                    padding: 3px;
                    color: white;
                    font-weight: bold;
                    margin-right: .5rem;
                }
            }
        }

        .choose-a-plan {
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            font-size: $font-size-body;
            color: var(--color-text-secondary);
            margin-bottom: 13px;
            margin-top: 16px;
        }

        .rin-tournament-plans {
            display: flex;
            gap: .5rem;
			margin-bottom: 1rem;

            @media (max-width: 1023px) {
                flex-wrap: wrap;
            }

            @media (min-width: 1024px) {
                flex-wrap: nowrap;
            }

            section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                border-radius: 10px;
                padding: .5rem;
                background-color: #F2F0FF;
                border: 1px solid rgba(123, 97, 255, 0.1);


                .plan-title {
                    font-weight: 700;
                    line-height: 14px;
                    text-align: center;
                    font-size: $font-size-body;
                    color: var(--color-text-secondary);
                }

                .plan-price {
                    font-weight: 800;
                    line-height: 26px;
                    text-align: center;
                    font-size: $font-height-h6;
                    color: var(--color-text-secondary);
                }

                button {
                    background: $premium-tournament;
                    color: white;
                }
            }
        }
    }

    .rin-pricing-federation-pro {
		@media screen and (max-width: 1024px) {
			padding-left: 3rem;
			padding-right: 3rem;
			.rin-card-title {
				min-height: 2.85rem;
			}
		}
        background: $pro-federation-card !important;
        border: 1px solid #fbe3ee !important;
        border-radius: 15px;

        .title-wrapper {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 13px;
            margin-top: .5rem;
            flex-wrap: wrap;

            .title-premium {
                font-style: italic;
                font-weight: 800;
                line-height: 24px;
                text-transform: uppercase;
                color: $ri-pink;
            }

            .title-plan {
                font-weight: 800;
                line-height: 24px;
                text-align: center;
                color: var(--color-text-secondary);
            }
        }

        .rin-card-header {
            margin-bottom: 18px;

            .rin-pricing-federation-label {
                display: flex;
                justify-content: center;

                span {
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 11px;
                    text-align: center;
                    color: #FFFFFF;
                    background: #EA6BA5;
                    border-radius: 3px;
                    padding: 2px 3px;
                    margin: 0 3px;
                    transform: skew(-15deg, 0);
                    display: inline-block;
                    margin-bottom: 1rem;
                }
            }

            .rin-federation-crossed {
                font-style: italic;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-decoration-line: line-through;
            }
        }

        ul {
            list-style: none;
            margin: 0 0 16px;
            padding: 0;

            li {
                font-weight: 700;
                line-height: 25px;
                font-size: $font-size-body;
                color: var(--color-text-secondary);

                .icon {
                    background-color: $ri-pink !important;
                    border-radius: 50%;
                    font-size: 9px;
                    padding: 3px;
                    color: white;
                    font-weight: bold;
                    margin-right: .5rem;
                }
            }
        }

        .get-started {
            margin-top: 33px;
            display: flex;
            justify-content: center;

            button {
                background-color: $ri-pink;
            }
        }
    }


    .rin-pricing-player-pro {
		@media screen and (max-width: 1024px) {
			padding-left: 3rem;
			padding-right: 3rem;
			.rin-card-title {
				min-height: 2.85rem;
			}
		}

        background: $pro-player-card !important;
        border: 1px solid #f8e7c6;
        border-radius: 15px;

        .title-wrapper {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 13px;
            margin-top: .5rem;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #2F3F7D;
            flex-wrap: wrap;

            .player-pro-label {
                background: $ri-gold;
                border-radius: 3px;
                color: white;
                transform: skew(-10deg, 0);
                padding: 0px 3px;
            }
        }

        .rin-card-header {
            margin-bottom: 18px;

            .rin-pricing-federation-label {
                display: flex;
                justify-content: center;

                span {
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 11px;
                    text-align: center;
                    color: #FFFFFF;
                    background: #EA6BA5;
                    border-radius: 3px;
                    padding: 2px 3px;
                    margin: 0 3px;
                    transform: skew(-15deg, 0);
                    display: inline-block;
                    margin-bottom: 1rem;
                }
            }

            .rin-federation-crossed {
                font-style: italic;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                text-decoration-line: line-through;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                font-weight: 700;
                line-height: 25px;
                font-size: $font-size-body;
                color: var(--color-text-secondary);

                .icon {
                    background-color: $ri-gold !important;
                    border-radius: 50%;
                    font-size: 9px;
                    padding: 3px;
                    color: white;
                    font-weight: bold;
                    margin-right: .5rem;
                }
            }
        }

        .get-started {
            margin-top: 33px;
            display: flex;
            justify-content: center;

            button {
                background-color: $ri-gold;
            }
        }

        .choose-a-plan {
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            font-size: $font-size-body;
            color: var(--color-text-secondary);
            margin-bottom: 13px;
        }

        .rin-player-plans {
            display: flex;
            gap: .5rem;
			margin-bottom: 1rem;

            @media (max-width: 1023px) {
                flex-wrap: wrap;
            }

            @media (min-width: 1024px) {
                flex-wrap: nowrap;
            }

            .rin-player-plan {
                border-radius: 10px;
                padding: .5rem;
                background-color: #FFF8E8;
                border: 1px solid $ri-gold;
                position: relative;


                .rin-card-title {
                    position: absolute;
                    top: -5px;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                }

                .rin-card-header {
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    height: 2rem;

                    span {
                        background: #2f3f7c;
                        border-radius: 20px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        color: #FFFFFF;
                        padding: 2px 4px;
                    }
                }

                .rin-card-body {
                    margin-bottom: 2rem;

                    .plan-period {
                        line-height: 1.125rem;
                        margin-top: 1.25rem;
                        color: var(--color-text-secondary);
                        font-size: $font-size-body;
                        text-align: center;
                        font-weight: 700;
                    }

                    .plan-price {
                        font-weight: 800;
                        line-height: 26px;
                        text-align: center;
                        color: var(--color-text-secondary);
                        font-size: $font-height-h6;

                        .price-period {
                            font-weight: 700;
                            font-size: 13px;
                            line-height: 19px;
                            color: #2F3F7D;
                        }
                    }

                    .twelve-months {
                        font-style: italic;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 16px;
                        text-align: center;
                        color: var(--color-text-secondary);
                        margin-top: .5rem;
                    }

                    .cancel-any-time {
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 16px;
                        text-align: center;
                        color: var(--color-text-secondary);
                        margin-top: .5rem;
                    }
                }

                .rin-card-footer {
                    display: flex;
                    justify-content: center;
                    margin-top: .5rem;
                    position: absolute;
                    bottom: 5px;
                    width: 100%;

                    button {
                        background: $ri-gold;
                        color: white;
                    }
                }
            }
        }
    }

	.rin-pricing-players-basic, .rin-pricing-player-pro,
	.rin-pricing-tournament-basic, .rin-pricing-tournament-premium,
	.rin-federation-basic {
		@media screen and (max-width: 1024px) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

	.rin-pricing-federation-pro {
		@media screen and (max-width: 1024px) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

    .rin-pricing-basic {
        background: #FFFFFF;
        border: 1px solid rgba(47, 63, 125, 0.2);
        border-radius: 15px;

		.rin-card-title {
			min-height: 2.85rem;
		}

        .title-wrapper {
            font-weight: 800;
            line-height: 24px;
            text-align: center;
            color: var(--color-text-secondary);
            margin-bottom: 13px;
            margin-top: .5rem;
        }

        .get-started {
            display: flex;
            justify-content: center;
            margin-top: 16px;
        }
    }

    .no-mobile-padding {
        @media (max-width: 1024px) {
            padding: 0;
        }
    }


    .rin-card {
        .rin-card-body {
            white-space: unset;
            overflow-x: unset;
        }

        ul {
            li {
                display: flex;

                &.pricing-list-center {

                    @media (min-width: 1023px) {
                        justify-content: center;
                    }

                    @media (max-width: 1024px) {
                        text-align: left;
                    }
                }
            }
        }
    }
}
