@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';

#image-section {
	input {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		opacity: 0;
		direction: ltr;
		cursor: pointer;
	}

	img {
		display: block;
		margin: auto;
		max-height: 30rem;
	}

	.rin-logo-images-container {
		position: relative;
		
		button {
			display: block;
			margin: 0.75rem auto;
		}
	}
}
