@import '../../tokens/colors.scss';



.rin-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2f3f7c;
    color: white;
    height: 71px;
    z-index: 999999;
    box-shadow: 0px -4px 7px 2px rgba(0, 0, 0, 0.25);
    padding-top: 0.5rem;


    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-around;

        li {
            &.active {
                color: rgba(255, 255, 255, 0.5);
            }

            .rin-nav-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 7px;
                padding: 5px 0;
                width: 53px;

                &.notification-wrapper {
                    position: relative;

                    .circle {
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 13px;
                        text-align: center;
                        font-weight: 700;
                        border: none;
                        color: #FFFFFF;
                        position: absolute;
                        background-color: #36D076;
                        float: right;
                        top: 1px;
                        left: calc(46% + 8px);
                    }
                }

                .icon {
                    font-size: 28px;
                }
            }

            .rin-navlink-description {
                font-size: 9px;
                text-align: center;
                white-space: nowrap;
            }

            &.rin-bottom-nav-login-or-create {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 12px 0px;

                .rin-nav-login-or-create-wrap {
                    display: flex;
                    width: 90%;
                    justify-content: space-around;
                    align-items: center;
                    white-space: nowrap;

                    .rin-btn-type-tertiary {
                        color: white;
                        border-color: white;
                    }

                    .italic {
                        font-style: italic;
                    }

					a {
						text-decoration: none;
					}

					.rin-btn-type-success {
						&.active,
						&.router-link-exact-active,
						&:active,
						&:hover {
							color: var(--color-text-primary);
							background: $color-background-success;
							border-color: $color-border-success;
						}
					}
                }
            }



        }
    }
}

.rin-main-content {
    &.authenticated {
        #landingPageContainer {

            > main {
                @media screen and (max-width: 1024px) {
                    padding-bottom: 71px;
                }
            }
        }

        .rin-bottom-nav {
            padding: 7px 0;
        }
    }
}
