@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';
.modal-pro-player-wrapper {
	display: flex;
	flex-direction: column;

	.choose-plan-wrapper {
		.rin-pro-player-modal-description {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-breadcrumbs;
			text-align: center;
			margin-bottom: 0.75rem;
			margin-top: 2rem;
		}

		.rin-pro-player-modal-plans-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0.5rem;
			margin-left: -1rem;
			margin-right: -1rem;

			@media screen and (max-width: 460px) {
				grid-template-columns: 1fr;
			}

			.rin-card {
				position: relative;
				background: var(--color-background-secondary-dark-1);
				border: 1px solid rgba($color: $color-background-primary, $alpha: 0.1);

				&.rin-popular-plan-container {
					border: 1px solid rgba($color: $color-background-primary, $alpha: 0.5);
				}

				.rin-card-title {
					position: absolute;
					top: -2px;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
					text-align: center;
				}

				.rin-card-header {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					height: 2rem;

					span {
						background: var(--color-background-primary);
						color: var(--color-text-primary);
						border-radius: 20px;
						padding: 0.25rem 0.5rem;
						display: table;
						font-size: 0.75rem;
					}
				}

				.rin-card-body {
					display: flex;
					flex-flow: column nowrap;
					align-items: center;

					.rin-pro-player-modal-plan-period {
						font-size: $font-size-h6;
						line-height: $font-height-h6;
						font-weight: $font-weight-h6;
						margin-top: 1.25rem;
					}

					.rin-pro-player-modal-plan-cost {
						font-size: $font-size-h2;
						line-height: $font-height-h2;
						font-weight: $font-weight-h2;

						span {
							font-size: $font-size-h5;
							line-height: $font-height-h5;
							font-weight: $font-weight-h5;
						}
					}

					.rin-pro-player-modal-plan-breakdown {
						font-size: $font-size-breadcrumbs;
						line-height: $font-height-breadcrumbs;
						font-weight: $font-weight-breadcrumbs;
						min-height: 1rem;
						margin-bottom: 0.5rem;
					}

					.cancel-any-time {
						font-size: 11px;
						line-height: 16px;
						text-align: center;
						color: var(--color-text-secondary);
						margin-bottom: .5rem;
					}
				}
			}
		}
	}
}
.rin-pro-player-modal-body-container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	color: var(--color-text-secondary);

	@media screen and (max-width: 1024px) {
		flex-flow: column nowrap;
	}

	.rin-pro-player-modal-image-container {
		@media screen and (max-width: 1024px) {
			text-align: center;
		}

		img {
			width: 21rem;
			height: 21rem;
			display: inline-block;
			margin-top: 3rem;

			@media screen and (max-width: 1024px) {
				margin-top: 0;
				width: 8.75rem;
				height: 7.75rem;
			}
		}
	}

	.rin-pro-player-modal-titles-container {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		.rin-pro-player-modal-supertitle {
			font-size: $font-size-h4;
			line-height: $font-height-h4;
			font-weight: $font-weight-h4;
		}

		.rin-pro-player-modal-title {
			display: flex;
			flex-flow: row nowrap;
			column-gap: 0.5rem;
			font-size: $font-size-h1;
			line-height: $font-height-h1;
			font-weight: $font-weight-h1;
			color: $color-text-pro;

			span.rin-type-label.pro {
				display: table;
				margin: auto 0;
				font-size: 1rem;
				line-height: 1rem;
			}
		}

		.rin-pro-player-modal-subtitle {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-breadcrumbs;

			margin-top: 0.75rem;
			margin-bottom: 1.5rem;
		}
	}

	.rin-pro-player-modal-bulletpoints {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
		list-style: none;
		padding: 0;

		@media screen and (max-width: 1024px) {
			margin-left: 0.75rem;
			margin-right: 0.75rem;
		}

		li {
			margin-bottom: 0.8125rem;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
		}

		.icon-check {
			color: var(--color-text-primary);
			background: $color-background-pro;
			border-radius: 50%;
			padding: 0.125rem;
			display: inline-block;
			margin-right: 0.5rem;
		}
	}

	
	
}
