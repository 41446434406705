//**** font families ****//
$font-family-primary: Montserrat;

//**** font sizes ****//
//body text, breadcurmbs, headings, lists
$font-size-h1: 1.375rem;
$font-size-h2: 1.125rem;
$font-size-h3: 1rem;
$font-size-h4: 0.875rem;
$font-size-h5: 0.8125rem;
$font-size-h6: 0.75rem;
$font-size-body: 0.75rem;
$font-size-body-compressed: 0.75rem;
$font-size-breadcrumbs: 0.6875rem;
$font-size-list: 0.8125rem;
$font-size-note: 0.6875rem;
//buttons
$font-size-button-s: 0.6875rem;
$font-size-button-m: 0.875rem;
$font-size-button-l: 1.125rem;

//filters
$font-size-filter: 0.8125rem;

//nav items
$font-size-nav-item-primary: 1rem;
$font-size-nav-item-primary-s: 0.8125rem;

//labels
$font-size-card-label: 0.6875rem;
$font-size-pro-label: 1.25rem;

//links
$font-size-outbound-link: 0.75rem;


//**** font line heights ****//
//body text, breadcurmbs, headings, lists
$font-height-h1: 1.875rem;
$font-height-h2: 1.625rem;
$font-height-h3: 1.5rem;
$font-height-h4: 1.25rem;
$font-height-h5: 1.1875rem;
$font-height-h6: 1.125rem;
$font-height-body: 1.25rem;
$font-height-body-compressed: 1rem;
$font-height-breadcrumbs: 1rem;
$font-height-list: 1.375rem;

//buttons
$font-height-button-s: 0.8125rem;
$font-height-button-m: 1.0625rem;
$font-height-button-l: 1.375rem;

//nav items
$font-height-nav-item-primary: 1.5rem;
$font-height-nav-item-primary-s: 0.8125rem;

// filter
$font-height-filter: 1.1875rem;

//labels
$font-height-card-label: 0.6875rem;

//links
$font-height-outbound-link: 0.875rem;


//**** font letter spacing ****//
$font-letter-spacing-breadcrumbs: 0.05em;

//**** font weights ****//
//body text, breadcurmbs, headings, lists
$font-weight-h1: 800;
$font-weight-h2: 800;
$font-weight-h3: 800;
$font-weight-h4: bold;
$font-weight-h5: bold;
$font-weight-h6: bold;
$font-weight-body: normal;
$font-weight-body-compressed: normal;
$font-weight-breadcrumbs: 500;
$font-weight-list: normal;
$font-weight-not-so-bold: 700;
//buttons
$font-weight-button-s: bold;
$font-weight-button-m: bold;
$font-weight-button-l: bold;
//nav items
$font-weight-nav-item-primary: bold;
//labels
$font-weight-label: 600;
$font-weight-card-label: 600;
$font-weight-card-info: 500;
//links
$font-weight-outbound-link: 600;
