@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.tab-pane[aria-labelledby='profile-section-ranking'] {
	position: relative;
}

.tab-pane[aria-labelledby='profile-section-ranking'] .upgrade-to-pro-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	border: 1px solid rgba(47, 63, 125, 0.1);
	border-radius: 15px;
	padding: 1.5rem;
}

.tab-pane[aria-labelledby='profile-section-ranking'] .upgrade-to-pro-button .rin-upgrade-to-pro-overlay {
	position: unset;
	transform: none;
}

.tab-pane[aria-labelledby='profile-section-ranking'] .upgrade-to-pro-button .rin-upgrade-to-pro-overlay button {
	margin: auto;
}

.infoIcon {
	display: inline-block;
	width: 1rem !important;
	height: 1rem !important;
	color: var(--color-text-primary);
	font-size: 0.75rem;
	font-weight: 800;
	background: var(--color-background-primary);
	border-radius: 50%;
	text-align: center;
	line-height: 1.0625rem;
	margin-left: 3rem;
}

@media (max-width: 1024px) {
	.infoIcon {
		margin-left: 0;
	}
}

.popover {
	z-index: 2001;
}

.counted-for-ranking-dropdown {
	min-width: 9rem;
}

.ranking-points-modal {
	h3 {
		font-weight: 800;
		font-size: $font-size-h3;
		line-height: $font-height-h3;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.rin-modal {
		padding: 1rem 0 !important;

		.rin-modal-content {
			padding: 1rem 0 0 !important;
		}
	}

	.rin-filter-tags-container {
		margin-left: 0.75rem;
	}

	.rin-filter-form-content {
		margin-left: 0.75rem;
	}
}

.rin-dt-table-action {
	color: $color-text-info-1;
	cursor: pointer;
}

.dataTable tbody tr.is-counted-for-ranking {
	background: #e6f8ff;
}

.tag-close {
	position: unset !important;
}

.rin-profileranking-placeholder {
	margin-left: auto;
	margin-right: auto;
}

.blurred {
	filter: blur(3px);
	pointer-events: none;
}

.no-ranking-message span {
	text-transform: lowercase;
	text-decoration: underline;
	cursor: pointer;
}

.no-ranking-message {
	font-size: 0.75rem;
	line-height: 1rem;
}

.profile-ranking-chart-container {
	margin-top: 2rem;
	padding-top: 2rem;
	background: var(--color-background-secondary);
	border-radius: 15px;
	border: 1px solid #f0f2f6;
}

.profile-ranking-table-container {
	padding-top: 1rem;
	background: var(--color-background-secondary);
	border-radius: 15px;
	border: 1px solid #f0f2f6;
	position: relative;
}

.profile-ranking-table-heading {
	font-weight: 800;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
}
