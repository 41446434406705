@import './register-email-password.scss';
@import './register-name.scss';
@import './register-dob.scss';
@import './register-gender.scss';
@import './register-country.scss';
@import './register-sport.scss';
@import './register-phone.scss';

@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-register-modal-wrapper {
	.rin-register-slide {
		.rin-btn-type-success {
			margin: 1rem auto 0;
		}
	}

	.vueperslides__arrow {
		top: -1.3125rem;
		color: var(--color-text-secondary);
		opacity: 1;

		&.vueperslides__arrow--next {
			display: none;
		}
	}

	.vueperslides__progress {
		background: $color-background-tertiary;
		color: $color-background-success-light-1;
		border-radius: 10px;

		& > div {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}

	.rin-modal .rin-modal-content {
		padding: 1rem 0 0;
	}
}

.rin-register-form {
	margin-top: 2rem;

	.rin-register-input-container {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		min-width: 18rem;

		.rin-register-label {
			font-size: $font-size-h6;
			line-height: $font-height-h6;
			font-weight: $font-weight-h6;
			margin-left: 1rem;
		}
		.rin-register-input {
			font-size: $font-size-body;
			line-height: $font-height-body;
			font-weight: $font-weight-body;
			height: 2.5rem;
			width: 100%;
			color: var(--color-text-secondary);
			border: 1px solid $color-border-tertiary;
			border-radius: 50px;
			padding: 0 1rem;

			&.has-error {
				border: 1px solid $color-border-danger;
			}
		}
	}
	.rin-btn-type-success {
		margin-top: 1rem;
	}

	.rin-register-errors-container {
		p {
			margin: 0;
			font-size: 0.75rem;
			font-weight: bold;
			color: $color-text-danger-2;
		}
	}

	.rin-dropdown-toggle {
		font-size: 0.75rem;
		min-width: 14rem;
	}

	.rin-register-checkbox-container {
		display: flex;
		flex-flow: row nowrap;
		gap: 1rem;
		align-items: center;
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;
		font-weight: 500;
		max-width: 18rem;

		.rin-base-checkbox {
			border-radius: 3px;
			border: 1px solid #d5d9e5;
			width: 1rem;
			height: 1rem;
			aspect-ratio: 1 / 1;
		}

		a {
			text-decoration: underline;
		}
	}

	.rin-register-form-container {
		display: flex;
		flex-flow: column nowrap;
		gap: 0.5rem;
		align-items: center;
	}
}
