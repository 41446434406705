﻿@import '../../tokens/fonts.scss';
@import '../../tokens/colors.scss';
@media screen and (max-width:799px) {
    .highest-skill-jump-wrapper {
        flex-direction: column;
    }
}

@media screen and (min-width:800px) {
    .highest-skill-jump-wrapper {
       flex-direction:row;
    }
}
.highest-skill-jump-wrapper {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    .rin-card-title {
        margin-bottom: 0.9rem;
    }
    .highest-skill-jump-header {
        font-size: $font-size-h4;
        line-height: $font-height-h4;
        font-weight: $font-weight-h4;
    }

    .rin-card {
        max-width: 45rem;
    }

    .highest-skill-jump-date {
        font-size: $font-size-h5;
        line-height: $font-height-h5;
        font-weight: $font-weight-card-label;
    }

    .rin-card-header, .rin-card-footer {
        flex-direction: column;
    }

    hr {
        margin: 0.5rem 0;
    }

    .highest-skill-jump-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-box {
            display: flex;
            flex-direction: row;
            gap: 0.75rem;

            .player-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 0.2rem;

                .p-name {
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-breadcrumbs;

                    &.winner {
                        font-weight: $font-weight-not-so-bold;
                    }
                }
            }

            .rating-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 0.2rem;

                .p-rating {
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-breadcrumbs;
                }

                .rating-diff {
                    color: $color-text-success;
                    margin-left:0.2rem;
                }
            }
        }

        .right-box {
            display: flex;

            .score-box {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                gap: 0.2rem;

                div {
                    text-align: center;
                    padding: 0.15rem 0.5rem;
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-h6;
                }

                border: 1px solid rgba(47, 63, 125, 0.1);
            }
        }
    }

    .event-name {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-breadcrumbs;
        color: $color-text-info-1 !important;
        text-decoration: underline !important;
    }

    .event-name:hover {
        cursor: pointer;
    }
}
