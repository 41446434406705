@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';


.rin-organisation-contacts {
    a {
        color: $color-text-info-1;
    }
}


.ranking-player-name {
    color: $color-text-info-1;
    display: flex;
    gap: 0.25rem;
}

