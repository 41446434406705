@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-freemium-campaing-content {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;

	& > * {
		text-align: center;
	}
	
	.rin-italic {
		font-style: italic;
	}

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin-bottom: 0;
	}

	h3 {
		font-size: $font-size-h3;
		line-height: $font-height-h3;
		font-weight: $font-weight-h3;
		margin-bottom: 1.5rem;
	}

	h6 {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
	}

	.rin-freemium-price {
		color: $color-text-pro;
		margin-top: 1rem;
	}

	.rin-fremium-redirect {
		color: $color-text-info;
		text-decoration: underline;
		cursor: pointer;
	}

	.rin-freemium-description {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.rin-freemium-help-text {
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;
		font-weight: $font-weight-body-compressed;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}

	.rin-freemium-contact {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
		text-decoration: none;
		margin-bottom: 2rem;
	}

	.rin-freemium-checkbox-wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 1.25rem;

		.rin-base-checkbox {
			background: #F8F8FA;
			border: 1px solid #D5D9E5;
			border-radius: 3px;
		}

		.rin-freemium-dont-show-again-text {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
		}
	}

	.rin-freemium-actions-wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
	}
}
