@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.ranking-index-container {
	padding: 0 5rem;

	@media screen and (max-width: 1024px) {
		padding: 0 2rem;
	}

	@media (max-width: 576px) {
		.ranking-main-table tr th {
			display: none;
		}

		.ranking-main-table tr td {
			padding: 0.5rem 0.125rem;
			white-space: nowrap;
		}

		.ranking-main-table .fav .icon {
			display: none !important;
		}

		.st-numb {
			padding-left: 0.625rem !important;
		}

		.part-name {
			text-align: left !important;
		}

		padding: 0;
	}

	span {
		&.inc {
			color: #37d076;
			transform: rotate(-90deg);
		}

		&.dec {
			color: #f43644;
			transform: rotate(90deg);
		}
	}

	.rin-ranking-follow-btn {
		margin-right: 0 !important;
	}

	.date-container {
		display: block;
		font-size: $font-size-body;
		font-weight: $font-weight-body;
		line-height: $font-height-body;
	}

	.rin-ranking-info-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.5rem;

		& > div {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			button.follow {
				display: block;
			}

			.icon-settings {
				color: $color-text-warning;
			}
		}
	}

	.rin-ranking-header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 0.5rem;
		margin-bottom: 0.675rem;

		h2 {
			margin: 0;
			font-size: $font-size-h2;
			font-weight: $font-weight-h2;
		}
	}

	.rin-ranking-weeks-navigation-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.75rem;
		padding-right: 0.25rem;

		.rin-ranking-buttons {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			h6 {
				margin: 0;
				font-size: $font-size-h6;
				font-weight: $font-weight-h6;
				line-height: $font-height-h6;
				cursor: pointer;
			}
		}
	}

	.rin-ranking-select-label {
		font-size: $font-size-body;
		font-weight: $font-weight-body;
		line-height: $font-height-body;
		margin-left: 0.625rem;
	}

	.rin-ranking-dropdown-container {
		margin-top: 0.5rem;
		display: flex;
		gap: 1.125rem;
		border-bottom: 1px solid $color-border-tertiary;
		padding-bottom: 1rem;
		flex-flow: row wrap;
		align-items: flex-end;
	}

	.rin-ranking-search-input {
		margin-left: auto;
		position: relative;

		@media screen and (max-width: 640px) {
			margin: auto;
			width: 100%;
		}

		input {
			border-radius: 50px;
			padding: 0 2rem;
		}

		.icon {
			position: absolute;
			top: 10px;
			right: 18px;
		}
	}

	.vdtnet-container {
		@media screen and (max-width: 640px) {
			margin-bottom: 4rem;
		}
	}

	.no-rankings {
		.rin-alert {
			margin-bottom: 2.625rem;
		}

		p {
			font-size: $font-size-body;
		}

		.btn {
			font-size: $font-size-h5;
		}
	}

	.banner-mobile {
		margin-bottom: 1rem
	}
}
