.rin-search-backdrop {
	@media screen and (min-width: 998px) {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin-top: 0;
	}

	background: transparent;
	display: flex;
	min-height: 30rem;
	z-index: 1002; //bigger than the main body modal to show over it when on dextop
	margin-bottom: 4rem; //make the last of the content visible and not hide under the bottom nav on mobile
	margin-top: 4.4375rem;

	.rin-search-wrap {
		display: flex;
		flex-flow: nowrap;
		padding-bottom: 10px;
		overflow: visible;
	}
}

.rin-search-col-1 {
	flex: 4 !important;
	display: flex;
	align-items: center;
}

.rin-search-col-2,
.rin-search-dummy {
	flex: 6 !important;
}

.rin-search {
	/* remove this styles */
	// max-width: 40em;
	// margin: 2rem auto;
	@media screen and (max-width: 997px) {
		width: 100%;
	}

	@media screen and (min-width: 998px) {
		min-width: 40rem;
		height: max-content;
	}
	//flex: 4;
	@media screen and (min-width: 998px) {
		/* position: absolute;*/
		z-index: 2;
		background: white;
		padding: 0 1rem 4rem;
		/*min-width: 35rem;
            max-width: 50rem;*/
		box-shadow: 0px 0px 1rem grey;
		// max-height: 70%;
		/*min-height: 30%;*/
		max-height: 30rem;
		overflow-y: auto;
		border-radius: 15px;
	}

	.rin-search-header {
		display: flex;
		justify-content: space-between;
		margin: 1rem 1rem;
		border-bottom: 1px solid rgba(47, 63, 125, 0.1);
		padding-bottom: 8px;

		.rin-search-header-title {
			font-weight: 800;
		}

		span {
			cursor: pointer;
			font-size: 20px;
		}
	}

	.rin-search-filters {
		white-space: nowrap;
		overflow-x: auto;

		@media screen and (max-width: 1024px) {
			padding: 0 10px;
		}
	}

	.rin-search-filter-container {
		display: inline-block;
		cursor:pointer;
		span {
			display: table;
			margin: auto;
		}
	}

	.rin-search-input {
		font-size: 12px;
		-webkit-border-radius: 18px 0 0 18px;
		-moz-border-radius: 18px 0 0 18px;
		border-radius: 18px;
		height: 28px;
		background: #f8f8f8;
		line-height: 18px;
		color: #4a4a4a;
		font-weight: 400;
		flex: 1;
		padding: 0 10px;

		&:focus-visible {
			outline: unset !important;
		}
	}
}

.rin-search-open {
	@media screen and (max-width: 997px) {
		display: none;
	}
}

.search-input-mobile {
	display: flex;
	margin-bottom: 1.5rem;
	padding: 0 10px;

	@media screen and (min-width: 998px) {
		display: none;
	}
}
