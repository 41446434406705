@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

//@TODO: discuss if export is needed, for now inject it into the sizing classes
.button-add-pointer {
	cursor: pointer;
}

.rin-btn-size-s {
	@extend .button-add-pointer;
	font-size: $font-size-button-s;
	line-height: $font-height-button-s;
	font-weight: $font-weight-button-s;
	padding: 0.25rem 0.75rem;
	border-width: 1px;
	border-radius: 50px;
	border-style: solid;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.375rem;

	&:focus {
		outline: unset;
	}
}

.rin-btn-size-m {
	@extend .button-add-pointer;
	font-size: $font-size-button-m;
	line-height: $font-height-button-m;
	font-weight: $font-weight-button-m;
	padding: 0.3125rem 0.9375rem;
	border-width: 2px;
	border-radius: 50px;
	border-style: solid;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;

	&:focus {
		outline: unset;
	}
}

.rin-btn-size-l {
	@extend .button-add-pointer;
	font-size: $font-size-button-l;
	line-height: $font-height-button-l;
	font-weight: $font-weight-button-l;
	padding: 0.4375rem 1.25rem;
	border-width: 2px;
	border-radius: 50px;
	border-style: solid;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.75rem;

	&:focus {
		outline: unset;
	}
}

/*this is admin panel button for dropdown*/
.rin-btn-size-fullwidth {
    @extend .button-add-pointer;
    font-size: $font-size-button-l;
    line-height: $font-height-button-l;
    font-weight: $font-weight-button-l;
    padding: 0.4375rem 1.25rem;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    height: 4rem;

    &:focus {
        outline: unset;
    }
}

.rin-btn-type-primary {
	color: var(--color-text-primary);
	background: var(--color-background-primary);
	border-color: $color-border-primary;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: $color-background-primary-light-2;
		border-color: $color-border-secondary-light-1;
	}

	&:disabled {
		background: rgba($color-background-primary, 0.5);
		border-color: rgba($color-border-primary, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

.rin-btn-type-secondary {
	color: var(--color-text-secondary);
	background: var(--color-background-secondary);
	border-color: $color-border-secondary;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: var(--color-background-primary);
		border-color: $color-border-primary;
	}

	&:disabled {
		background: rgba($color-background-secondary, 0.5);
		border-color: rgba($color-border-secondary, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

.rin-btn-type-tertiary {
	color: $color-text-tertiary;
	background: $color-background-tertiary;
	border-color: $color-border-tertiary;
	//in order to use rgba for border
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: var(--color-background-primary);
		border-color: $color-border-primary;
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
	}

	&:disabled {
		color: rgba($color-text-tertiary, 0.5);
		pointer-events: none;
	}
}

.rin-btn-type-success {
	color: var(--color-text-primary);
	background: $color-background-success;
	border-color: $color-border-success;
	//in order to use rgba for border
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: $color-background-success-light-1;
		border-color: $color-border-success-light-1;
	}

	&:disabled {
		background: rgba($color-background-success, 0.5);
		border-color: rgba($color-border-success, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

.rin-btn-type-warn {
	color: var(--color-text-primary);
	background: $color-background-warning;
	border-color: $color-border-warning;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: $color-background-warning-light-1;
		border-color: $color-border-warning-light-1;
	}

	&:disabled {
		background: rgba($color-background-warning, 0.5);
		border-color: rgba($color-border-warning, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

.rin-btn-type-danger {
	color: var(--color-text-primary);
	background: $color-background-danger;
	border-color: $color-border-danger;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: $color-background-danger-light-1;
		border-color: $color-border-danger-light-1;
	}

	&:disabled {
		background: rgba($color-background-danger, 0.5);
		border-color: rgba($color-border-danger, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

.rin-btn-type-premium {
	color: var(--color-text-primary);
	background: $color-background-premium;
	border-color: $color-border-premium;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: var(--color-text-primary);
		background: $color-background-premium-light-1;
		border-color: $color-border-premium-light-1;
	}

	&:disabled {
		background: rgba($color-background-premium, 0.5);
		border-color: rgba($color-border-premium, 0.5);
		//in order to use rgba for border
		-webkit-background-clip: padding-box; /* for Safari */
		background-clip: padding-box;
		pointer-events: none;
	}
}

/*.rin-btn-type-info {
	color: $color-text-info;
	background: $color-background-success;
	border-color: $color-border-success;

	&.active,
	&.router-link-exact-active,
	&:active,
	&:hover {
		color: $color-text-success;
		background: var(--color-background-secondary);
		border-color: $color-border-success;
	}
}
*/
