@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.rin-admin-page {
    padding-bottom: 1rem;

    &:not(.tn-in-create) {
        padding-top: 1rem;
    }

    @media screen and (max-width: 1024px) {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

.rin-teamleague-create-edit {
    .rin-teamleague-section-header {
        display: flex;
        gap: 1rem;

        .popover-wrapper {
            color: var(--color-text-secondary);

            p {
                font-weight: 400;
            }
        }
    }
}

.container-create-teamleague {
    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1rem;
    }

    h3 {
        font-size: 0.8rem;
    }
}

.stripeContent {
    max-width: 30rem;
    
    a {
        text-decoration: underline;
    }
}


.direction-buttons {
    gap: .25rem;
}

.direction-buttons button {
    border-radius: 5px;
    padding: 10px 5px;
}

.division-team-matches-per-day {
    text-align: center;
    font-size: 14px;
    font-weight: 600 !important;
    width: 3.5rem;
    border: unset;
    border-radius: 5px;
}

.entry-fee-price{
    width: 3.5rem;
}