﻿@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.player-profile-main-content {
    background-color: var(--color-background-secondary-dark-1);

    .profile-name {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: $font-weight-h1;
    }

    .rin-card-body {
        overflow: unset;
    }

    .rin-profile-header {
        .rin-profile-header-record-right > div > .rating-widget-wrapper > .blurred > .rin-dropdown-container {
            margin: auto;
        }

        .rin-profile-header-record-right {
            width: 100%;

            .rin-card {
                padding: 1rem 0;
            }

            .ratin-widget {
                margin-left: 2rem;
            }

            .skill-placeholder {
                justify-content: space-evenly;
                padding: 0 2rem;
                display: flex;
                align-items: center;
                white-space: normal;
                font-size: $font-size-h6;
                font-weight: $font-weight-h6;
                line-height: $font-height-h6;
            }
        }


        .rin-card {
            width: 20rem;
        }
    }

    .show-more-button-wrapper {
        margin-top: 1.25rem;
        width: 8%;
        margin: 2.5rem auto 0 auto;
    }

    @media only screen and (max-width: 1023px) {
        .last-events-played-card {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .show-more-button-wrapper {
            width: auto;
            max-width: 5rem;
        }

        .profile-name {
            font-size: $font-size-h1;
            line-height: $font-height-h1;
            font-weight: $font-weight-h1;
        }
    }

    .statistics-wrapper {
        margin: 1.8rem 0;



        td {
            background-color: $color-background-secondary-dark-3 !important;
            font-size: $font-size-h6 !important;
            line-height: $font-height-h6 !important;
            font-weight: $font-weight-card-info !important;

            strong {
                font-size: $font-size-h2 !important;
                line-height: $font-height-h2 !important;
                font-weight: $font-weight-h2 !important;
            }
        }

        .statistics-header {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            align-items: center;
            margin-bottom: 1.8rem;
        }

        th {
            width: 50%;
            font-size: $font-size-h4 !important;
            line-height: $font-height-h4 !important;
            font-weight: $font-weight-h4 !important;
        }
    }

    @media only screen and (min-width: 1023px) {

        .statistics-card, .chart-card, .opponents-card, .last-events-played-card {
            margin: 2rem 0 !important;
        }
    }

    @media only screen and (max-width: 1023px) {

        .statistics-card, .chart-card, .opponents-card, .last-events-played-card {
            margin: 1rem 0 !important;
        }
    }

    .chart-card {
        .rin-card-body {
            padding-top: 1rem;
        }
    }

    .placeholder-card {
        .rin-card-body, .rin-card-title {
            text-align: center;
        }

        .rin-card-footer {
            .placeholder-link {
                text-decoration: underline;
            }

            text-align: center;
            white-space: normal;
        }

        .placeholder-title {
            font-size: $font-size-h2;
            line-height: $font-height-h2;
            font-weight: $font-weight-h2;
        }

        .placeholder-body {
            font-size: $font-size-h6;
            line-height: $font-height-h6;
            font-weight: $font-weight-card-info;
        }
    }

    .first-name, .last-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11ch;
        margin: auto;
    }

    .opponents-info-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;

        .opponents-info-header {
            font-size: $font-size-h3;
            line-height: $font-height-h3;
            font-weight: $font-weight-h3;
            text-align: center;
            margin-top: 1rem;
        }

        .opponents-info-dropdown-wrapper {
            display: flex;
            justify-content: center;
        }

        @media only screen and (max-width: 1023px) {
            .opponents-info-body {
                flex-direction: column !important;
                align-items: center;
                gap: 1.5rem;
            }

            .opponents-info-cards-wrapper {
                width: 100% !important;
                gap: 0.5rem;

                .rin-card {
                    width: auto !important;
                }
            }

            .highest-jump-wrapper {
                width: 100% !important;
            }
        }

        .opponents-info-body {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            /*   @media only screen and (min-width: 1024px) {
                .last-events-played-card {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }*/

            .opponents-info-cards-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                width: 50%;
            }
        }

        .rin-card {
            width: 11rem;

            img {
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 50%;
                margin: 0.5rem 0;
            }

            .rin-card-header {
                text-align: center;
                overflow: unset !important;
            }

            .last-name {
                font-size: $font-size-h4;
                line-height: $font-height-h4;
                font-weight: $font-weight-h4;
                text-align: center;
            }

            .first-name {
                font-size: $font-size-h6;
                line-height: $font-height-h6;
                font-weight: $font-weight-card-info;
                text-align: center;
            }


            .opponents-info-card-footer {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;

                hr {
                    border: 1px solid #E4E5ED !important;
                    margin: 0.8rem;
                    width: 50%;
                }

                .matches-counter {
                    font-size: $font-size-h2;
                    line-height: $font-height-h2;
                    font-weight: $font-weight-h2;
                }

                .matches-text {
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-card-info;
                }
            }
        }

        .highest-skill-jump-wrapper {
            .rin-card {
                width: 100%;

                .rin-card-header {
                    .highest-skill-jump-date {
                        text-align: left
                    }
                }
            }

            margin: 0;
            height: 100%;
        }

        .highest-jump-wrapper {
            width: 50%;
        }
    }

    .last-events-played-wrapper {
        .last-events-played-header {
            font-size: $font-size-h3;
            line-height: $font-height-h3;
            font-weight: $font-weight-h3;
            text-align: center;
            margin: 1rem 0;
        }


        .rin-card {
            gap: 0.8rem;
        }

        .mobile-carousel {

            .rin-card {
                width: 100% !important;
            }

            .vueperslides__track-inner {
                gap: 0.4rem;
            }
        }

        .last-events-played-carousel-wrapper {
            display: flex;

            &.mobile {
                display: block !important;
            }

            .rin-card {
                width: 23rem;

                .rin-card-title {
                    text-align: center;
                    overflow: unset;
                }

                .rin-card-header {
                    text-align: center;
                    overflow: unset;
                }

                .rin-card-body {
                    flex-direction: column;
                    text-align: center;
                }

                .first-name {
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-card-info;
                }

                .last-name {
                    font-size: $font-size-h3;
                    line-height: $font-height-h3;
                    font-weight: $font-weight-h3;
                }
            }
        }

        .last-events-played-card-body {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 0.9rem;

            img {
                border-radius: 50%;
                height: 4.5rem;
                width: 4.5rem;
            }

            .winner {
                border-color: $color-border-success;
            }

            .loser {
                border-color: $color-border-danger;
            }
        }

        .desktop-no-carousel {
            display: flex;
            justify-content: center;
            flex-direction: row;
            gap: 0.8rem;
        }

        .rin-card-footer {
            div {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
            }


            a {
                &:not(.first-name):not(.last-name) {
                    color: $color-text-info-1;
                    font-size: $font-size-h6;
                    line-height: $font-height-h6;
                    font-weight: $font-weight-card-info;
                }
            }

            .result-label {
                font-weight: $font-weight-h2;
                font-size: $font-size-h2;
                line-height: $font-height-h5;
                display: inline-block;
                align-items: center;
                color: var(--color-text-primary);
                background: var(--color-background-primary);
                border-radius: 3px;
                transform: skew( -15deg );
                width: auto;
                padding: 0 0.2rem;
            }

            .event-link-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 30ch;
                display: inline-block;
            }

            [class^=icon-] {
                display: inline-block;
            }

            .detailed-results {
                display: flex;
                gap: 0.3rem;
                font-size: $font-size-note;
                line-height: $font-height-button-s;
                font-weight: $font-weight-card-info;
            }
        }
    }

    .club-name, .club {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-card-info;
    }

    .club {
        color: var(--color-text-secondary);
    }

    .club-name {
        color: $color-text-info-1;
    }

    .x-remove-club {
        font-size: 11px;
        font-weight: $font-weight-h6;
    }

    p.id {
        font-size: $font-size-h6;
        font-weight: $font-weight-card-info;
        line-height: $font-height-h6;
        font-family: Montserrat;

        .icon-rin-sports-squash {
            font-size: $font-size-h2;
        }
    }

    .line-separator {
        margin: 0 0.5rem;
    }

    .mobile-header {
        width: 100%;

        .upper-part {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 0 1rem;

            div:last-of-type {
                text-align: center;
            }

            img {
                display: inline-block;
                width: 11.5rem;
                height: 11.5rem;
                margin: 0 auto;
                padding: 0;
                border-radius: 100%;
            }
        }

        @media only screen and (max-width: 1023px) {

            .upper-part {
                margin-bottom: 1.125rem;
            }
        }
    }

    .show-more-button-wrapper {
        z-index:2;
        position:relative;
    }
}
