@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-messenger-content {
    background-color: var(--color-background-secondary-dark-1);

    .rin-messenger {
        @media (min-width: 1024px) {
            display: flex;
            /*height: 85vh;*/
            overflow: hidden;
            flex-wrap: wrap;

            .messenger-header {
                width: 100%;
                padding: 2rem 15px 1rem;
                font-weight: 800;
                font-size: 18px;
                line-height: 26px;
                color: #2F3F7D;
            }

            hr {
                width: 100%;
                margin: 0 15px;
            }

            .rin-chat-list {
                width: 28%;
            }
        }

        @media (min-width: 1025px) and (max-width: 1700px) {
            .rin-chat-box {
                height: 70vh !important;
            }

            .messages-main-container {
                height: 53vh !important;
            }

            .rin-chat-contacts-wrapper {
                height: 63vh !important;
            }
        }

        .chat-search {
            padding: 1rem 0;
            display: flex;
            gap: 1rem;

            .main-back-btn {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .search-label {
                display: flex;
                flex: 1;
                margin: 0;

                .icon {
                    border-radius: 20px 0 0 20px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    padding-left: 1rem;
                    border: 1px solid rgba(47, 63, 125, 0.1);
                    border-right: none;
                }

                .search-input {
                    flex: 1;
                    text-align: left;
                    padding: .5rem .8rem;
                    border-radius: 0 20px 20px 0;
                    background-color: #fff;
                    border: 1px solid rgba(47, 63, 125, 0.1);
                    border-left: none;

                    &:focus, &:focus-visible {
                        border: 1px solid rgba(47, 63, 125, 0.1);
                        border-left: none;
                        outline: none;
                    }
                }
            }
        }

        .rin-chat-list {

            .messenger-header {
                font-weight: 800;
                font-size: 16px;
                line-height: 24px;
                color: #2F3F7D;
                padding: 15px 0 8px;
            }

            hr {
                margin: 0;
            }

            .rin-chat-contacts-wrapper {
                background-color: #fff;
                margin-left: -15px;
                margin-right: -15px;
                padding: 5px 15px 0;
                border-radius: 15px 15px 0 0;
                border: 1px solid rgba(47, 63, 125, 0.1);

                @media (max-width: 1024px) {
                    min-height: 50vh;
                    border-bottom: none;
                    border-left: none;
                    border-right: none;
                }

                @media (min-width: 1024px) {
                    height: 69vh;
                    overflow-y: auto;
                }

                .rin-chat-contact {
                    display: flex;
                    flex-direction: row;
                    gap: .5rem;
                    margin: 8px 0;
                    cursor: pointer;

                    .chat-img {
                        img {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            margin-right: .3rem;
                            object-fit: cover;
                        }
                    }

                    .chat-info {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-grow: 1;
                        overflow: hidden;

                        .chat-name {
                            font-size: .9375rem;
                            line-height: 1.3333;
                            font-weight: 500;

                            .chat-name-text {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }

                        .chat-lm {
                            font-size: .8125rem;
                            font-weight: 400;
                            line-height: 1.2308;
                            color: #B0B3B8;
                            display: flex;

                            .chat-lm-text {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .chat-dot-separator {
                                div {
                                    width: 10px;
                                    text-align: center;
                                    font-weight: 600;
                                }
                            }

                            .chat-lm-time {
                            }
                        }
                    }

                    .chat-actions {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.highlighted-chat {
                        background-color: #dfe0e1;
                        border-radius: 28px;
                    }

                    &.new-message {

                        .chat-lm-text {
                            color: darkblue;
                            font-weight: 600;
                            color: #0095ff;

                            .chat-name-text {
                                font-weight: bold;
                            }
                        }

                        .chat-circle {
                            height: 10px;
                            width: 10px;
                            background-color: #2f3f7c;
                            border-radius: 50%;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            .place-holder {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 1rem 2rem;

                .start-conversation-ph {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    color: #2F3F7D;
                    text-align: center;
                }

                .use-search-ph {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    color: #2F3F7D;
                    padding: 1.5rem 0;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        .rin-chat-box {
            background-color: #fff;

            .chatbox-placeholder {
                height: 100%;
                align-items: center
            }

            @media (min-width: 1024px) {
                margin-top: 1rem;
                border-radius: 15px;
                border: 1px solid rgba(47, 63, 125, 0.1);
                width: 68%;
                height: 75vh;
                overflow: hidden;
            }

            .messages-main-container {
                overflow-y: auto;

                @media(max-width: 1023px) {
                    margin-top: 4rem;
                    padding-bottom: 7.5rem;
                    height: calc(100vh - 61px - 74px + 35px);
                }

                @media (min-width: 1024px) {
                    height: 55vh;
                    margin-top: 5rem;
                }

                @media (min-width: 1424px) {
                    height: 59vh;
                    margin-top: 5rem;
                }
            }

            .chatbox-playfield {
                position: relative;

                @media(max-width: 1024px) {
                    margin-top: -2rem;
                }

                .chat-box-header {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    background: white;
                    width: 100%;
                    border-bottom: 1px solid rgba(47, 63, 125, 0.1);

                    @media (max-width: 1024px) {
                        position: fixed;
                        padding: 1.5rem 0.5rem 1rem;
                        border-bottom: 1px solid #f1f1f1;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }

                    @media (min-width: 1024px) {
                        position: absolute;
                        padding: 1.5rem 0.5rem;
                    }

                    .chat-title {
                        font-weight: 600;
                        font-size: 1.1rem;
                        white-space: nowrap;
                        width: 85%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .icon-back {
                        @media (min-width: 1024px) {
                            display: none;
                        }
                    }
                }

                .chat-message {
                    display: flex;
                    margin-bottom: 1.5rem;

                    .chat-img {
                        width: 39px;
                        height: 39px;
                        border-radius: 50%;
                        margin-right: 0.6rem;
                        object-fit: cover;
                    }

                    &.is-sent {
                        justify-content: flex-end;

                        @media (min-width:1024px) {
                            margin-right: 1.5rem;
                        }

                        .message-wrapper {
                            text-align: right;

                            .chat-message-text {
                                color: #fff;
                                background: #2F3F7D;
                            }
                        }
                    }

                    &.is-recived {
                        justify-content: flex-start;

                        .message-wrapper {
                            .chat-message-text {
                                color: #2F3F7D;
                                background: rgba(47, 63, 125, 0.1);
                            }
                        }
                    }

                    .message-wrapper {
                        display: inline-block;
                        max-width: 80%;
                        margin: 0;

                        .chat-message-text {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            border-radius: 10px;
                            padding: 8px 13px;
                            margin-bottom: 5px;
                            display: inline-block;
                        }
                    }

                    .message-date {
                        font-style: italic;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 14px;
                        color: rgba(47, 63, 125, 0.5);
                        padding: 0 8px;
                    }
                }

                .chat-input-wrapper {
                    display: flex;
                    justify-content: center;

                    @media (max-width: 1024px) {
                        position: fixed;
                        bottom: 0;
                        align-items: center;
                        width: 100%;
                        padding: 15px 5px 25px;
                        background: #F8F8FA;
                        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
                        margin-left: -15px;
                    }


                    .chat-input {
                        border-radius: 50px 0 0 50px;
                        border: 1px solid rgba(47, 63, 125, 0.1);
                        border-right: none;
                        width: 85%;
                        padding: 5px 15px;
                        outline: none !important;
                    }

                    .icon {
                        border: 1px solid rgba(47, 63, 125, 0.1);
                        border-left: none;
                        border-radius: 0 50px 50px 0;
                        padding: 8px 15px;
                        background: white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
