﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

@media screen and (max-width: 768px) {
    div.iadmin-container {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width:768px) {
    div.iadmin-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width:1024px) {
    div.iadmin-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.iadmin-container {
    display: grid;
    gap: 0.625rem;
    margin: 0 auto;

    .event-card-header > div > span:first-of-type {
        padding: 0 0.3125rem;
    }

    hr {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }

    .no-events {
        background: rgba(47, 63, 125, 0.1);
    }

    .rin-card {
        max-width: 23.4375rem;
        min-width: 20rem;

		@media screen and (max-width: 1024px) {
			max-width: 100%;
		}
    }

    .collapse-body {
        max-height: 0;
        transition: max-height 0.35s ease-out;
        overflow: hidden;
        position: relative;
    }

    .collapse-body-collapsed {
        max-height: 100px;
        transition: max-height 0.35s ease;
        overflow: hidden;
        position: relative;
    }

    .header-text {
        font-size: $font-size-h4;
        line-height: $font-height-h4;
        font-weight: $font-weight-h4;
    }

    .collapse-toggle > span, .collapse-toggle > a > span {
        font-size: $font-size-h6;
        line-height: $font-height-breadcrumbs;
        font-weight: 500;
    }

    .collapse-toggle {
        cursor: pointer;
        justify-content: space-between;
        display: flex;
    }


    a.event-link-public, a.event-link-admin {
        font-size: $font-size-h6;
        line-height: $font-height-list;
        font-weight: 500;
        margin-left: 0.5625rem;
        text-decoration: underline;
    }

    a.americano-link {
        text-decoration: underline;
    }

    a.event-link-admin {
        color: $color-text-warning;
    }

    span.icon.icon-v {
        transform: rotate(270deg);
        font-size: 0.6em;
        transform-origin: center center;
        transition-property: transform;
        transition-duration: 0.3s;

        &.collapsed {
            transform: rotate(360deg);
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
    }

    span, a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .rin-card-footer {
        margin: auto auto 0;
    }

    .rin-card-header {
        margin: 0 -1.25rem;
        max-width: unset;

        .no-events {
            font-size: $font-size-h6;
            line-height: $font-height-h4;
            font-style: italic;
            font-weight: $font-weight-card-info;
            padding: 0 1rem;
        }
    }
}
