﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.ask-for-consent {
    .consent-error {
        border-color: $color-text-danger-2 !important;
    }

    .ask-for-consent-wrapper {
        display: flex;
        flex-direction: column;

        .ask-for-consent-title {
            font-size: $font-size-h6;
            line-height: $font-height-body-compressed;
            font-weight: $font-weight-not-so-bold;
            margin-bottom: 0.75rem;
        }

        li {
            font-size: $font-size-h6;
            line-height: $font-height-h3;
            font-weight: $font-weight-breadcrumbs;
        }

        .checkbox-text {
            font-size: $font-size-h6;
            line-height: $font-height-body-compressed;
            font-weight: $font-weight-breadcrumbs;
        }

        .rin-alert {
            font-size: $font-size-h6;
            line-height: $font-height-body-compressed;
            font-weight: $font-weight-breadcrumbs;
            font-style: italic;
            background-color: unset !important;
            padding-left: 2rem !important;
        }
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: row;
    }

    .rin-base-checkbox {
        border-radius: 3px;
        border: 1px solid #d5d9e5;
        width: 1rem;
        height: 1rem;
        aspect-ratio: 1 / 1;
    }
}
