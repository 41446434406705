﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.rin-tournament-enter-result {
    .rin-simple-tab-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .rin-simple-tab {
            width: 50%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 1px solid rgba(47, 63, 125, 0.1);
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom: none;
            font-weight: 700;
            font-size: 13px;
            line-height: 19px;
            color: #2f3f7d;
            background: #f5f5f9;
            cursor: pointer;
            height: 2.5rem;

            &.active {
                background: #ffffff;
            }
        }
    }

}

