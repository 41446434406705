@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';
.rin-private-match-container {
	position: unset !important;

	@media screen and (max-width: 1024px) {
		position: fixed !important;
	}

	.has-error {
		border: 2px solid red !important;
	}
}

#rin-private-match-create-container {
	max-width: 48.875rem;
	margin: auto;

	.rin-add-result-tabs-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.375rem;

		@media screen and (max-width: 1024px) {
			justify-content: start;
			margin-bottom: 0.875rem;
			margin-left: 1rem;
		}

		#singles,
		#doubles {
			display: none;
		}

		.rin-base-radio-control-label {
			padding: 0.25rem 0.75rem;

			color: var(--color-text-secondary);
			font-size: $font-size-h5;
			line-height: $font-height-h5;
			font-weight: $font-weight-h5;

			border-radius: 20px;

			cursor: pointer;
		}

		input[type='radio']:checked + label {
			border: 2px solid $color-border-secondary;
		}
	}

	.rin-add-result-container-card {
		margin-bottom: 1.25rem;

		& > .rin-card-body {
			overflow: initial; //to place search player result "modal"
		}

		@media screen and (max-width: 1024px) {
			margin-bottom: 0.625rem;
		}
	}

	.rin-add-result-form-details-card {
		margin-bottom: 1.5rem;

		.rin-card-body {
			display: flex;
			flex-flow: column nowrap;
			row-gap: 1.25rem;
			margin-left: 6.5rem;
			overflow: unset;

			& > div {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: 1rem;

				input {
					background: $color-background-tertiary !important;
					color: var(--color-text-secondary) !important;
					font-size: $font-size-h5;
					line-height: $font-height-h5;
					font-weight: $font-weight-h5 !important;
					padding: 0.375rem 1rem;
					max-width: 8.125rem;
					cursor: pointer;
				}

				label {
					color: var(--color-text-secondary);
					font-size: $font-size-h5;
					line-height: $font-height-h5;
					font-weight: $font-weight-h5;
					margin-bottom: 0;
				}

				.input-calendar-icon {
					position: absolute;
					left: 9.5rem;
				}
			}

			@media screen and (max-width: 1024px) {
				margin-left: unset;
			}
		}
	}

	.rin-add-result-button-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		margin-bottom: 1.5rem;
	}

	.rin-add-result-player-card {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
		margin-bottom: 0.25rem;

		position: relative; //to place the search player result "modal"

		& > .rin-card-body > div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			column-gap: 0.75rem;
			padding: 0.125rem;

			& > span {
				font-size: 1.75rem;
			}

			.rin-form-control-input {
				color: var(--color-text-secondary) !important; //@TODO: remove when old stlyes are removed
				font-weight: $font-weight-h4 !important; //@TODO: remove when old stlyes are removed
				border: none;
				border-bottom: 1px solid rgba($color-border-secondary, 0.7);
			}
		}
	}

	.rin-add-result-players-container {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		& > span {
			font-size: $font-size-h4;
			line-height: $font-height-h4;
			font-weight: $font-weight-h4;
		}

		& > div {
			width: 21.5rem;
		}

		@media screen and (max-width: 1024px) {
			flex-flow: column;
			row-gap: 0.375rem;

			& > div {
				width: 100%;
			}
		}
	}

	.rin-add-result-scores-container {
		display: flex;
		flex-flow: column nowrap;
		max-width: 22rem;
		margin: auto;
		margin-top: 2rem;

		@media screen and (max-width: 1024px) {
			margin-top: 1rem;
		}

		input {
			width: 100%;
			border: 1px solid $color-border-tertiary;
			border-radius: 7px;
			color: var(--color-text-secondary) !important; //@TODO: remove when old stlyes are removed
			font-weight: $font-weight-h4 !important; //@TODO: remove when old stlyes are removed
			line-height: $font-height-h4;
			font-size: $font-size-h4;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			text-align: center;
		}

		& > div {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
		}

		.rin-add-result-player-score-input-container {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;

			label {
				color: var(--color-text-secondary);
				font-size: $font-size-body-compressed;
				line-height: $font-height-body-compressed;
				font-weight: 600; // Why? Beacause fuck you, that's why!

				max-width: 10rem;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
		}

		.rin-add-result-sets-tiebreaks-labels-container {
			justify-items: center;
			margin-top: 1rem;
			color: var(--color-text-secondary);
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: 500; // Why? Beacause fuck you, that's why!
		}

		.rin-add-result-player-sets-input-container {
			& > div {
				display: flex;
				flex-flow: row nowrap;
				gap: 0.25rem;
				margin: 0.25rem;
			}
		}

		.rin-add-result-player-tiebreak-input-container {
			& > div {
				display: flex;
				flex-flow: row nowrap;
				gap: 0.25rem;
				margin: 0.25rem;
			}
		}
	}
}

// @TODO: export
.rin-add-result-player-search-card-container {
	position: absolute;
	top: 3.625rem;
	left: 0.25rem;
	z-index: 1;
	max-height: 40vh;

	flex-flow: column nowrap !important;
	align-items: stretch !important;
	padding-left: 1rem;
	padding-right: 1rem;

	& > .rin-card-header {
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;
		font-weight: 500;
		margin-top: 0.375rem;
		margin-bottom: 0.75rem;
		text-align: center;
	}

	& > .rin-card-body > div {
		flex-flow: column nowrap !important;
		margin-bottom: 0.25rem;
	}
}

.rin-add-result-player-search-container {
	margin-bottom: 0.25rem;
	cursor: pointer;

	&.disabled {
		color: rgba($color-text-secondary, 0.5);
		pointer-events: none;
		cursor: default;
	}

	& > .rin-card-header {
		font-size: $font-size-h5;
		line-height: $font-height-h5;
		font-weight: $font-weight-h5;

		& > span i {
			margin-right: 0.375rem;
		}
	}

	& > .rin-card-body {
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;

		.icon {
			font-size: 1rem;
		}

		& > span {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

			& > .rin-add-result-player-search-info {
				max-width: 90%;
				white-space: nowrap;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	& > .rin-card-footer {
		font-size: $font-size-body-compressed;
		line-height: $font-height-body-compressed;
		color: $color-text-danger-2;
	}
}

.result-added-modal {
	display: flex;
	flex-flow: column nowrap;
	margin: 2rem 5.5rem;
	align-items: stretch;

	@media screen and (max-width: 1024px) {
		margin: 2rem 0;
	}

	.icon-plus-private-match {
		font-size: 2.25rem;
		margin-bottom: 1.5rem;
	}

	.result-added-text {
		font-size: $font-size-h2;
		line-height: $font-height-h2;
		font-weight: $font-weight-h2;

		@media screen and (max-width: 1024px) {
			margin-bottom: 2;
		}
	}

	span {
		margin: auto;
	}

	button {
		margin-top: 1rem;
	}
}
