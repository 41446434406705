.rin-search-result-group {

	.rin-search-result-group-title {
		position: relative;
		z-index: 1;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #2f3f7d;
		margin-bottom: 20px;
		margin-top: 20px;

		&:before {
			border-top: 1px solid rgba(47, 63, 125, 0.5);
			content: '';
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			bottom: 0;
			width: 95%;
			z-index: -1;
		}

		span {
			background: #fff;

			@media screen and (max-width: 1024px) {
				background: #f8f8fa;
			}
			padding: 0 15px;
		}
	}

	.rin-card {
		margin-bottom: 0.3125rem;
	}

	.rin-search-result-group-body {
		display: flex;
		gap: 5px;
		align-items: center;
		margin-bottom: 5px;

		h4 {
			margin-bottom: unset;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			color: #2f3f7d;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 1rem;
		}
	}

	.rin-search-show-more-wrapper { 
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.rin-search-result-group-description {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #2f3f7d;
	}

	
}


