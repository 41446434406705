//**** font colors ****//
$color-text-primary: #ffffff;
$color-text-secondary: #2f3f7d;
$color-text-tertiary: #2f3f7d;
$color-text-success: #36d076;
$color-text-info: #00b9ff;
$color-text-warning: #F77700;
$color-text-warning-2: #FF9A03;
$color-text-danger: #AD1519;
$color-text-danger-light-1: #FF4552;
$color-text-danger-2: #EF3340;
$color-text-danger-3: #A41B28;
$color-text-pro: #FBA500;
$color-text-premium: #7B61FF;
$color-text-info-1: #0276FF;
$color-text-primary-admin: #009fdd;
$color-text-success-2: #007156;
$color-text-success-3: #107966;
$color-text-dark-0: #010101;
$color-text-gray-0: #727272;


//**** background colors ****//
$color-background-primary: #2f3f7d;
$color-background-primary-light-1: #4F61A7;
$color-background-primary-light-2: #4B61B9;
$color-background-primary-dark-1: #25326A;
$color-background-secondary: #ffffff;
$color-background-secondary-dark-1: #F8F8FA;
$color-background-secondary-dark-2: #F5F5F7;
$color-background-secondary-dark-3: #F0F2F6;
$color-background-tertiary: rgba($color-background-primary, 0.1);
$color-background-success: #36d076;
$color-background-success-light-1: #42E586;
$color-background-info: #00b9ff;
$color-background-info-light-1: #02B9FF;
$color-background-info-light-2: #d1ecf1;
$color-background-light: #f8f8f8;
$color-background-danger: #EF3340;
$color-background-danger-light-1: #FF4552;
$color-background-danger-2: #AD1519;
$color-background-warning: #FF9A03;
$color-background-warning-light-1: #FFBC3A;
$color-background-warning-light-2: #fff3cd;
$color-background-quaternary: #9258BF;
$color-background-pro: #FBA500;
$color-background-premium: #7B61FF;
$color-background-premium-light-1: #8F80C3;
$color-background-popular: #FBA500;
$color-background-notpro: #D4D5DB;
$color-background-primary-admin: #009fdd;
$color-background-secondary-admin: #0b4e6e;
$color-background-secondary-admin-2: #00435e;
$color-background-premium: #7B61FF;
$color-background-notifications: #36D076;
$color-background-label-inverted: #F0F2F6;
//**** border colors ****//
$color-border-primary: #ffffff;
$color-border-primary-dark-1: #E8E8E8;
$color-border-primary-dark-2: #cecece;
$color-border-secondary: #2f3f7d;
$color-border-secondary-light-1: #4B61B9;
$color-border-tertiary: rgba($color-background-primary, 0.1);
$color-border-success: #36d076;
$color-border-success-light-1: #42E586;
$color-border-info: #00b9ff;
$color-border-danger: #EF3340;
$color-border-danger-light-1: #FF4552;
$color-border-danger-2: #AD1519;
$color-border-warning: #FF9A03;
$color-border-warning-light-1: #FFBC3A;
$color-border-premium: #7B61FF;
$color-border-premium-light-1: #8F80C3;
$color-border-pro: #FBA500;
//**** alert colors ****//
$alert-background-danger: #ad15191a;
$alert-background-info: #2f3f7d1a;
$alert-background-success: #058e6e1a;


/************* NON-THEME COLORS START HERE ************/

//**** input colors ****//
$input-background-error: #fa9a9a;



//**** monetization colors ****//
$premium-tournament: #7B61FF;
$premium-tournament-card: #F8F7FF;

$pro-federation-card: #fef5f9;
$pro-player-card: #FFFBF2 ;

$ri-pink: #EA6BA5;
$ri-gold: #FBA500;



//**** label colors ****//
$tournament-label: #7B61FF;
$clubleague-label: #02B9FF;
$teamleague-label: #4F61A7;
$organization-label: #EA6BA5;
$team-label: #107966;
$americano-label: #25B0A8;
$ranking-label: #A41B28;
$player-label: #FF9A03;
$replay-label: #EF3340;
$video-planned-label: #02B9FF; // same as club league label but edita didn't give us different color for this label