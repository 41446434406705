﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

.rin-profile-matches {

    .matches-container {
        justify-items: center;
        margin: 1rem auto;

        @media screen and (min-width:1024px) {
            column-gap: 1rem;
            row-gap: 1rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width:1023px) {
            padding: 0 0.5rem;

            .rin-matches-card {
                margin-bottom: .5rem;
            }
        }
    }

    .rin-collapse-toggle {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;

        .tab-title {
            font-size: $font-size-h3;
            line-height: $font-height-h3;
            font-weight: $font-weight-h3;
        }

        .icon-v {
            font-size: $font-size-body;
            line-height: $font-height-body;
            font-weight: $font-weight-body;
            display: flex;
            align-items: flex-start;
            transform: rotate(0deg);
            transition: 0.3s;

            &.reversed {
                transform: rotate(180deg);
                transition: 0.3s;
                display: flex;
                align-items: flex-end;
            }
        }
    }

    .rin-matches-card {

        .rin-matches-name-wrapper {
            display: flex;
            align-items: center;
            gap: .2rem;

            .rin-matches-title {
                font-size: $font-size-h4;
                line-height: $font-height-h2;
                font-weight: $font-weight-not-so-bold;
            }
        }

        .match-header-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            & > .header-item {
                display: flex;
                font-weight: 600;
                font-size: 13px;
                line-height: 22px;
                align-items: center;
                gap: .5rem;

                .icon {
                    width: 1rem;
                    display: flex;
                    justify-content: center;
                }

                .rin-matches-max-width {
                    max-width: 20rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .rin-matches-card-body-container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            column-gap: 3px;
            font-size: $font-size-body;
            line-height: $font-height-body;
            font-weight: 500;

            .space {
                height: 10px;
            }

            .rin-matches-card-participant-names-container {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding-right: .5rem;

                .rin-match-participant-name-wrapper {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;

                    &.is-winner {
                        font-weight: bold;
                    }
                }
            }

            .rin-matches-score-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;

                &.doubles-match {
                    height: 50%;
                    align-self: center;
                }

                .rin-matches-scorebox {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    border: 1px solid $color-border-primary-dark-1;
                    padding: 2px 8px;
                    border-radius: 3px;
                    font-weight: bold;
                    align-items: center;
                }
            }

            .rin-matches-card-message {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: 0.25rem;
            }
        }

        .matches-buttons-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (max-width:420px) {
                justify-content: center;
                flex-direction: column;
                align-items: center;

                .has-action-buttons {
                    margin-bottom: 1rem;
                }
            }

            .right-buttons,
            .left-buttons {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                column-gap: 5px;

                .rin-label {
                    transform: skew(-15deg);
                    font-style: italic;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 11px;
                    padding: 2px 5px;
                    color: #A41B28;
                    background-color: rgba(164, 27, 40, 0.15);
                    border-radius: 3px 5px;
                    margin: 0;
                }
            }
        }
    }

    .matches-showmore {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 1.5rem 0;
    }

    .blurred-wrapper {
        position: relative;
    }

    .finished-wrapper {
        position: relative;

        .upgrade-to-pro-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            border: 1px solid rgba(47, 63, 125, 0.1);
            border-radius: 15px;
            padding: 1.5rem;

            .rin-upgrade-to-pro-overlay {
                position: unset;
                transform: none;

                button {
                    margin: auto;
                }
            }
        }
    }

    .rin-matches-placeholder {
        text-align: center;
        padding: 1rem 2rem;

        img {
            margin-bottom: 2rem;
        }

        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
        }
    }


    .matches-container {


        &.blurred {
            filter: blur(3px);
            pointer-events: none;
        }

        .matches-header-left-side {
            // text-decoration: underline;
            overflow: hidden;
            max-width: 28.125rem;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (min-width: 300px) and (max-width: 500px) {
                max-width: 20rem;
            }

            .icon-plus-private-match {
                font-size: 22px;
            }
        }

        .card-location, .card-ranking, .rin-matches-card-participant-names-container {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media screen and (min-width: 1024px) {
                max-width: 20rem;
            }

            @media screen and (max-width: 1024px) {
                max-width: 10rem;
            }
        }

        .card-ranking {
            text-decoration: underline;
        }

        .match-title-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }






        .match-title-container {
            margin-bottom: 14px;
        }

        .icon-custom {
            font-size: 14px;
        }

        .rin-type-label {
            margin-bottom: 7px;
        }

        hr {
            border: 1px solid rgba(47, 63, 125, 0.1);
        }

        hr.upper-row {
            margin: 12px 0 12px 0;
        }

        hr.lower-row {
            margin: 12px 0 12px 0;
        }

        .underlined {
            text-decoration: underline;
        }

        .invitation-text {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #2f3f7d;
            margin-left: 7px;
        }


        .invitation-text {
            white-space: normal;
        }
    }
}
