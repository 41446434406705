﻿.footer-buttons {
    display: flex;
    gap: 1rem;
    margin: 1rem 0 2rem;

    .icon-reverse {
        transform: rotate(-180deg);
    }

    div {
        width: 50%;
        button, a {
            width: 100%;
            border-radius: 5px;
        }
    }

    a {
        text-decoration: none;
    }
}
