﻿@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.profile-opponents {
    //non-modal
    .outside-modal {
        .opponents-grid {
            gap: 0.3rem;
            display: grid;
        }

        @media only screen and (max-width: 576px) {
            .opponents-grid {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @media only screen and (min-width: 577px) and (max-width:745px) {
            .opponents-grid {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        @media only screen and (min-width: 746px) and (max-width:800px) {
            .opponents-grid {
                grid-template-columns: repeat(6, 1fr);
            }
        }


        @media only screen and (min-width: 801px) and (max-width:950px) {
            .opponents-grid {
                grid-template-columns: repeat(6, 1fr);
            }
        }


        @media only screen and (min-width: 951px) and (max-width:1199px) {
            .opponents-grid {
                grid-template-columns: repeat(8, 1fr);
            }
        }


        @media only screen and (min-width: 1200px) {
            .opponents-grid {
                grid-template-columns: repeat(9, 1fr);
            }
        }
    }
    //non modal end
    //modal
    .modal-body {
        .opponents-grid {
            gap: 0.3rem;
            display: grid;
        }

        @media only screen and (max-width: 576px) {
            .opponents-grid {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @media only screen and (min-width: 577px) and (max-width:745px) {
            .opponents-grid {
                grid-template-columns: repeat(5, 1fr);
            }
        }

        @media only screen and (min-width: 746px) and (max-width:800px) {
            .opponents-grid {
                grid-template-columns: repeat(6, 1fr);
            }
        }


        @media only screen and (min-width: 801px) {
            .opponents-grid {
                grid-template-columns: repeat(6, 1fr);
            }
        }
     
    }
    //modal end
    .opponents-grid > .rin-card {
        padding: 1rem 1rem 0.7rem 1rem;
        min-width: 6rem;
        border-radius: 0.65rem;
    }

    .opponents-grid > .rin-card > .rin-card-title, .rin-card-header, .rin-card-footer {
        display: flex;
        justify-content: center;
    }

    .opponents-grid > .rin-card > .rin-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: visible;
    }

    .opponents-grid {
        .stats-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .see-more {
            text-decoration: underline;
            color: $color-text-info-1;
            font-size: $font-size-h6;
            line-height: $font-height-h6;
            font-weight: $font-weight-card-info;
        }

        .see-more:hover {
            cursor: pointer;
        }

        .stats-wrapper-inner {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .opponents-grid {
            display: flex;
            gap: 0.3rem;
            flex-wrap: wrap;
        }

        .first-name {
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
        }

        .last-name {
            font-weight: bold;
            font-size: 11px;
            line-height: 13px;
        }

        .first-name, .last-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 11ch !important;
            font-family: Montserrat;
            font-style: normal;
            color: #2F3F7D;
        }

        .matches {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: #2F3F7D;
        }

        .winrate {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 8px;
            line-height: 10px;
            color: #2F3F7D;
        }

        .player-img {
            width: 61px;
            height: 61px;
            border-radius: 50%;
        }

        img {
            border-radius: 50%;
            margin-bottom: 5px;
        }

        .blurred {
            filter: blur(3px);
            pointer-events: none;
        }

        .rin-card-title {
            margin-bottom:0.2rem;
        }
    }

    .opponents-empty-title {
        font-size: $font-size-h2;
        line-height: $font-height-h2;
        font-weight: $font-weight-h2;
        margin-bottom: 1rem;
    }

    .upgrade-to-pro-button {
        .rin-upgrade-to-pro-overlay {
            top:25% !important;
        }
    }

    .opponents-empty-small {
        font-size: $font-size-h6;
        line-height: $font-height-h6;
        font-weight: $font-weight-card-info;
        margin-top: 1rem;
    }

    .highest-skill-title {
        font-size: $font-size-h3;
        line-height: $font-height-h3;
        font-weight: $font-weight-h3;
        text-align: center;
        margin: 2.5rem 0 1rem 0;
    }

    .opponents-header-row {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 1.5rem;

        .header {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
            color: #2F3F7D;
            align-self: center;
        }

        .dropdown-wrapper {
            max-width: 210px;
            align-self: center;
        }

        .search-input {
            background: var(--color-background-secondary);
        }

        @media only screen and (min-width: 601px) {
            .search-wrapper {
                width: 28rem;
                align-self: center;
            }
        }

        @media only screen and (max-width: 600px) {
            .search-wrapper {
                width: 22rem;
                align-self: center;
            }
        }


        .mb-20 {
            margin-bottom: 20px !important;
        }

        .dropdown-menu {
            width: 90%;
        }


        .rating-name, .dropdown-menu, .dropdown-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dropdown-toggle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .mb-18 {
            margin-bottom: 18px;
        }
    }

    .visible {
        visibility: visible !important;
    }

    @media(max-width:768px) {
        .opponents-wrapper {
            border-radius: 0 !important;
        }
    }

    .opponents-wrapper {

        .mb-19 {
            margin-bottom: 19px;
        }



        .w-15 {
            width: 15% !important;
        }

        .empty-box {
            height: 206px;
        }

        .mb-22 {
            margin-bottom: 22px;
        }

        .label-custom {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 12px;
            color: #2F3F7D;
        }

        .show-more {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: #FFFFFF;
            padding: 5px 10px;
        }

        .dropdown-menu {
            left: -40px !important;
        }

        .filter-by {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: #2F3F7D;
        }

        .sticky {
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            align-self: flex-start;
            z-index: 1;
            background-color: #f8f8f8;
            height: 100%;
            min-width: 80px !important;
        }

        .blurred {
            filter: blur(3px);
            pointer-events: none;
        }
    }
}
