@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-premium-tournament-container {
	border-top: 1px solid $color-border-tertiary;
	border-bottom: 1px solid $color-border-tertiary;

	.icon-star {
		font-size: 2.5rem;
		color: $color-text-premium;
		margin-bottom: 0.5rem;
	}

	.rin-premium-tournament-header {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		.rin-premium-tournament-supertitle {
			font-size: $font-size-h4;
			line-height: $font-height-h4;
			font-weight: $font-weight-h4;
		}

		.rin-premium-tournament-title {
			font-size: $font-size-h1;
			line-height: $font-height-h1;
			font-weight: $font-weight-h1;
			color: $color-text-premium;
			display: flex;
			flex-flow: row nowrap;

			.rin-type-label {
				display: table;
				margin: auto 0;
				margin-right: 0.5rem;
				font-size: 1rem;
				line-height: 1rem;
				font-weight: 800;
			}
		}

		.rin-premium-tournament-subrtitle {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-body-compressed;
			margin-top: 0.25rem;
			margin-bottom: 1rem;
		}
	}

	.rin-premium-tournament-body {

		.rin-premium-tournament-current-plan {
			position: relative;

			.rin-card-title {
				position: absolute;
				top: -7px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				text-align: center;
			}

			.rin-card-body {
				display: flex;
				flex-flow: column nowrap;
				align-items: center;
				margin-top: 1rem;

				h6 {
					font-size: $font-size-h6;
					line-height: $font-height-h6;
					font-weight: $font-weight-h6;
				}

				span {
					font-size: $font-size-body-compressed;
					line-height: $font-height-body-compressed;
					font-weight: $font-weight-body-compressed;
				}
			}
		}

		.rin-premium-tournament-bulletpoints {
			font-size: $font-size-h6;
			line-height: $font-height-h6;
			font-weight: $font-weight-h6;
			list-style: none;
			padding: 0;

			@media screen and (max-width: 1024px) {
				margin-left: 0.75rem;
				margin-right: 0.75rem;
			}

			li {
				margin-bottom: 0.8125rem;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
			}

			.icon-check {
				color: var(--color-text-primary);
				background: var(--color-background-primary);
				border-radius: 50%;
				padding: 0.125rem;
				display: inline-block;
				margin-right: 0.5rem;
			}
		}

		.rin-premium-tournament-player-count {
			color: $color-text-danger-light-1;
			font-size: $font-size-h4;
			line-height: $font-height-h4;
			font-weight: $font-weight-h4;
			margin-top: 1.5rem;
			text-align: center;
		}

		.rin-premium-tournament-description {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-breadcrumbs;
			text-align: center;
			margin-bottom: 0.75rem;
			margin-top: 2rem;
		}

		.rin-premium-tournament-plans-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 0.5rem;
			margin-left: -1rem;
			margin-right: -1rem;


			.rin-card {
				background: var(--color-background-secondary-dark-1);
				border: 1px solid rgba($color: $color-background-primary, $alpha: 0.1);
				padding: 0.75rem;
				align-items: center;
				justify-content: space-between;

				&.not-eligible {
					background: #D5D9E5;
					opacity: 30%;
				}

				.rin-card-body {
					display: flex;
					flex-flow: column nowrap;
					align-items: center;

					.rin-premium-tournament-modal-plan-limits {
						font-size: $font-size-h6;
						line-height: $font-height-h6;
						font-weight: $font-weight-h6;
						margin-top: 0.75rem;
						margin-bottom: 1rem;
					}

					.rin-premium-tournament-modal-plan-cost {
						font-size: $font-size-h2;
						line-height: $font-height-h2;
						font-weight: $font-weight-h2;
						margin-bottom: 1rem;
					}
				}
			}
		}

		.rin-premium-tournament-additional-info {
			font-size: $font-size-body-compressed;
			line-height: $font-height-body-compressed;
			font-weight: $font-weight-body-compressed;

			& > div {
				margin-top: 0.75rem;
			}
		}
	}

	.rin-premium-tournament-footer {
		margin-bottom: 1rem;

		.rin-alert {
			margin: 0 -2rem;
			text-align: center;
		}
	}
}
