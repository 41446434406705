@import '../../tokens/colors.scss';

.rin-profile-manage-events-organisations-container {

	padding-top: 1rem;

	.rin-simple-tab-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;

		.rin-simple-tab {
			width: 50%;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			text-align: center;

			border: 1px solid rgba(47, 63, 125, 0.1);
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			border-bottom: none;

			font-weight: 700;
			font-size: 13px;
			line-height: 19px;
			color: #2f3f7d;
			background: #f5f5f9;

			cursor: pointer;
			height: 2.5rem;

			&.active {
				background: #ffffff;
			}
		}
	}

	.star-indicator {
		vertical-align: text-top !important;
	}
}

.rin-collapse-toggle {
	padding-top:1rem;
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	cursor: pointer;

	.tab-title {
		font-size: $font-size-h3;
		line-height: $font-height-h3;
		font-weight: $font-weight-h3;
	}

	.icon-v {
		font-size: $font-size-body;
		line-height: $font-height-body;
		font-weight: $font-weight-body;
		display: flex;
		align-items: flex-start;
		transform: rotate(0deg);
		transition: 0.3s;

		&.reversed {
			transform: rotate(180deg);
			transition: 0.3s;
			display: flex;
			align-items: flex-end;
		}
	}
}