﻿@import '../../tokens/colors.scss';

.star-indicator {
    color: $color-text-premium;
}

.star-indicator-s {
    font-size: 1rem;
}

.star-indicator-m {
    font-size: 1.25rem;
}

.star-indicator-l {
    font-size: 2rem;
}

.star-indicator-xl {
    font-size: 2.1rem;
}
