@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-register-dob-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin-bottom: 0;
	}

	.rin-register-input-container {
		margin: auto;
		align-items: center !important;
	}

	.date-dropdowns {
		margin-left: 1rem;
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		select {
			background: $color-background-tertiary;
			border-radius: 50px;
			color: var(--color-text-secondary);
			font-size: $font-size-h6;
			line-height: $font-height-h6;
			font-weight: 700;
			padding: 0.375rem 1rem;
			border: 1px solid $color-border-tertiary;
		}
	}
}
