﻿@import '../tokens/colors.scss';
@import '../tokens/fonts.scss';

.rin-modal {

    .close {
        color: var(--color-text-secondary);
    }

    .modal-content {
        background-color: $color-background-light;
    }
}
