﻿@import '../tokens/fonts.scss';
@import '../tokens/colors.scss';

@media screen and (max-width:1023px) {
    div.bookmarks-container {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width:1024px) {
    div.bookmarks-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.bookmarks-container.flash:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    animation: slide 1s infinite;
    /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
/* animation */

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.bookmarks-container {
    display: grid;
    justify-content: center;
    margin: 0 auto;
    gap: 1rem;

    @media screen and (max-width: 1024px) {
        gap: 0.5rem;
    }

    hr {
        border: 1px solid rgba(47, 63, 125, 0.1);
    }

    .bookmark-title {
        font-size: $font-size-h4;
        font-weight: 700;
        line-height: 20px;
    }

    b {
        font-weight: 700;
        font-size: 13px;
        line-height: 22px;
    }

    .rin-card > .rin-card-header {
        margin-bottom: 14px;
    }
    /*.rin-card {
        max-width: 464px;*/
    /*margin-left: 5px;
        margin-right: 5px;*/
    /*}*/

    .rin-label {
        transform: skew(-15deg);
        background-color: red;
        font-family: Montserrat;
        font-style: italic;
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        padding: 0 2px;
        color: #FFFFFF;
        background-color: #EF3340;
        border-radius: 1px 3px;
    }

    .event-name-link {
        text-decoration: underline;
        font-size: $font-size-h4;
    }

    .tooltip-buttons-wrapper {
        max-width: 110px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        button {
            width: 80%;
            margin-bottom: 5px;
        }
    }

    .followWrapper, .followSmall {
        font-size: $font-size-note;
        padding: 0 5px 0 5px;
        text-align: center;
        font-weight: 500;
    }

    .donate-button {
        display: flex;
        justify-content: end;
        flex-direction: column;
    }

    .followWrapper {
        margin: auto;
    }

    .followBig {
        margin: auto;
        font-size: $font-size-h2;
        font-weight: $font-weight-not-so-bold;
    }

    .followSmall {
        display: flex;
        flex-direction: column;
        justify-content: end;

        span {
            margin-bottom: auto;
        }
    }

    .watermark {
        filter: blur(6px);
    }

    .watermark-square {
        background: white;
        position: absolute;
        display: grid;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        border-radius: 30px;
        box-shadow: 0px 0px 1.9rem rgba(0, 0, 0, 0.25);
        padding: 1.75rem 0.625rem 0.625rem 0.625rem;
        height: 14.375rem;
        max-width: 22.5rem;

        button {
            margin: auto;
        }

        img {
            width: 40px;
        }
    }

    .bookmark-flag {
        margin-right: 0.6rem;
    }

    .bookmark-country, .bookmark-city, .bookmark-status, .bookmark-ranking, .bookmark-date, .bookmarks-club {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
    }

    .bookmark-title-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 3px;
    }

    .bookmarks-header-left-side {
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;


        @media screen and (max-width:530px) {
            max-width: 18.75rem;
        }

        @media screen and (min-width:550px) {
            max-width: 26.875rem;
        }
    }

    .bookmarks-header-right-side {
        max-width: 20%;
    }
}

.show-more-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.5rem 0;
}
