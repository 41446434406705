﻿@import '../tokens/colors.scss';

.rin-toggle {
    .vue-js-switch:not(.toggled) {
        .v-switch-core {
            background-color: $color-background-tertiary !important;

            .v-switch-button {
                background-color: #2f3f7d !important;
                height: 1.6475rem !important;
                width: 1.7375rem !important;
            }
        }

        .v-switch-label {
            color: #2f3f7d !important;
        }
    }

    .vue-js-switch.toggled {
        .v-switch-core {
            background-color: #2f3f7d !important;

            .v-switch-button {
                background-color: #ffffff !important;
                height: 1.6375rem !important;
                width: 1.7375rem !important;
            }
        }

        .v-switch-label {
            color: #ffffff !important;
        }
    }


    label.vue-js-switch {
        margin-bottom: 0;
    }
}
