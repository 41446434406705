@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-register-gender {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	h1 {
		font-size: $font-size-h1;
		line-height: $font-height-h1;
		font-weight: $font-weight-h1;
		margin: auto;
	}

	.rin-register-label {
		font-size: $font-size-h6;
		line-height: $font-height-h6;
		font-weight: $font-weight-h6;
	}

	.rin-register-input {
		//lol, thanks random SO stranger
		margin-top: -1px;
		vertical-align: middle;
	}
}
