﻿@import '../../../tokens/fonts.scss';
@import '../../../tokens/colors.scss';

.rin-tournament-classes-step {
    .rin-add-class-header {
        font-size: 16px;
    }

    .rin-add-new-class {

        .rin-dropdown-toggle {
            width: 100%;
        }

        .form-group {
            label {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.25rem;

                &.checkbox-label {
                    font-weight: normal;
                    cursor: pointer;
                }
            }

            &.rin-checkbox-wrapper {
                display: flex;
                gap: 2rem;
            }
        }

        .dates-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 0.5rem;
            gap: 0.2rem;

            .play-dates {
                border: 2px solid #2f3f7d;
                background-color: #f3f3f3;
                color: #2f3f7d;
                text-align: center;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                gap: 0.2rem;
                padding: 0.1rem;
                height: 5rem;
                width: 3.125rem;
                cursor: pointer;

                &.selected {
                    border: 1px solid #2f3e7d;
                    background-color: #2f3f7d;
                    color: #f2f3f3;
                    text-align: center;
                    border-radius: 5px;
                }

                div {
                    font-size: 1rem;
                }
            }
        }


        .class-save-btns {
            button {
                width: 100%;
                border-radius: 5px;
            }
        }

        .input-error {
            background-color: #fa9a9a;
            color: #a94442;
            border-color: red;
        }

        .dropdown-error {
            button {
                background-color: #fa9a9a;
                color: #a94442;
                border-color: red;
            }
        }

        .playDateSelection {
            .rin-alert {
                margin-bottom: 0.2rem;
            }
        }
    }
}
