﻿@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.send-email-wrapper {
    margin: 2rem 25%;
    display: flex;
    flex-direction: column;
    h4 {
        margin-bottom:1rem;
    }
    .recipient {
        display: flex;
        flex-direction: row;
        gap: 0.4rem;
        align-items: baseline;
    }

    .email-subject {
        padding: 0.33rem 0.66rem;
        font-size: $font-size-h3;
        line-height: $font-height-h3;
        background: white;
        border-radius: 0.25rem;
        border: 1px solid lightgray;
        width: 100%;
        outline: unset;
        margin-bottom: 0.5rem;
    }

    div:last-of-type {
        margin: 0.3rem 0;
    }

    .rin-base-checkbox {
        opacity: 0.5;
    }

    .label {
        font-size: $font-size-h5;
        font-weight: $font-weight-h5;
        line-height: $font-height-h5;
        color: $color-text-danger-3;
    }
}
