@import '../../tokens/colors.scss';
@import '../../tokens/fonts.scss';

.rin-page-top-bar-container {
    background: $color-background-primary-dark-1;
    color: $color-text-primary;
    font-size: $font-size-note;
    line-height: $font-height-body-compressed;
    padding: 0.8125rem 0;

    .check-it {
        text-decoration: underline;
        cursor: pointer;
        font-weight: $font-weight-not-so-bold;
    }

    & > div, .rin-page-top-bar-monetization-description {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        column-gap: 1rem;

        @media screen and (max-width: 1024px) {
            margin: 0 0.5rem;
            column-gap: 0.5rem;
        }
    }

    .rin-page-top-bar-back-btn {
        font-size: 1rem;
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: -10px;
            bottom: -10px;
            right: -10px;
            display: inline-block;
            width: 1px;
            height: 28px;
            margin: auto;
            padding: 0;
            background: rgba(255, 255, 255, 0.2);

            @media screen and (max-width: 1024px) {
                right: -4px;
            }
        }
    }

    .rin-no-worries-bar-text {
        .rin-top-bar-click-here {
            font-weight: 700;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

header.fixed .rin-page-top-bar-container {
    display: none;
}
