@import '../../tokens/colors.scss';

.rin-navbar-container {
	position: relative; //to position the arrows for the scroll on dextop screen
	z-index: 10;
	background: var(--color-background-secondary-dark-1);

	.rin-nav-bar-primary {
		background: var(--color-background-secondary);
		box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.15);
		padding: 0 1rem;

		@media screen and (max-width: 1024px) {
			padding: 0;
		}
	}

	.rin-nav-bar-scrollable {
		width: 100%;
		overflow-x: hidden;
		overflow-y: hidden;
		text-align: center;
		white-space: nowrap;
		//@TODO: add shorthand function for mobile and dextop
		@media screen and (max-width: 1024px) {
			&::-webkit-scrollbar {
				width: 64px;
				height: 2px;
			}

			&::-webkit-scrollbar-thumb {
				background: lightgray;
			}

			overflow-x: auto;
		}
	}

	#left-arrow {
		position: absolute;
		left: 1rem;
		top: 1rem;
		z-index: 3;
		transform: rotate(180deg);

		img {
			margin-bottom: 0; //override some stupid class that sets it to 30px
		}
	}

	#right-arrow {
		position: absolute;
		right: 1rem;
		top: 1rem;
		z-index: 3;

		img {
			margin-bottom: 0; //override some stupid class that sets it to 30px
		}
	}
}
